import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'

import config from '../../config.json'
import axios from 'axios'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Logo from '../../assets/images/Logo.png'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MLink color="inherit" href="#">
                BizSmart
            </MLink>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = (props) => {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const showError = (<span style={{ color: 'red' }}>{error}</span>)

    useEffect(() => {
        const token = localStorage.getItem('bsToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const setNotificationUser = async (profile_id) => {
        const OneSignal = window.OneSignal
        await OneSignal.push(function () {
          OneSignal.setExternalUserId(profile_id);
        });
    }

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const passwordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPassword(value)
        setError(null)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const data = {
            email,
            password,
        }
        const url = config.url + '/login'
        axios.post(url, data).then(response => {
            const user = response.data
            setNotificationUser(user.id)
            localStorage.setItem('bsToken', user.token)
            localStorage.setItem('bsUser', JSON.stringify(user))
            var location = '/dashboard'
            props.history.push(location)
        }).catch(error => {
            if (error.response) {
                // console.log(error.response.data.detail);
                setError(error.response.data.detail)
            } else if (error.request) {
                console.log(error.request)
                setError('Try after some time.')
            } else {
                console.log(error)
                setError('Try after some time.')
            }
            setDisabled(false)
            localStorage.clear()
        })
    }

    return (
        <div className="bg-auth">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={Logo} alt="Logo" className="img-fluid" />
                    <Typography component="h1" variant="h5" className="mb-4">
                        Sign in
                    </Typography>
                    <form className={classes.form} onSubmit={onSubmit} >
                        <TextField
                            required={true}
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                            onChange={emailChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            inputProps={{ minLength: 6, maxLength: 50 }}
                            onChange={passwordChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            className={classes.submit}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <MLink href="/#/forgot" variant="body2">
                                    Forgot password?
                                </MLink>
                            </Grid>
                            <Grid item>
                                {showError}
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <Box mt={8} className="pb-5">
                    <Copyright />
                </Box>
            </Container>
        </div>
    )
}

export default Login
