import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import MuiPhoneNumber from 'material-ui-phone-number'

import { dateToString, stringToDate } from '../../functions'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateCustomer = (props) => {
    const customerId = props.match.params.customerId
    const genders = ['Male', 'Female']
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [pan, setPan] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [gender, setGender] = useState('Male')
    const [dob, setDob] = useState(null)
    const [gst, setGst] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('bsToken')

    const getUser = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const customer = response.data
            if (customer.dob !== '') {
                const date = stringToDate(customer.dob)
                setDob(date)
            }
            setFirstName(customer.first_name)
            setLastName(customer.last_name)
            setEmail(customer.email)
            setNumber(customer.number)
            setPan(customer.pan)
            setAadhar(customer.aadhar)
            setGender(customer.gender)
            setGst(customer.gst)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const handlePanChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPan(value)
    }

    const handleAadharChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setAadhar(value)
    }

    const handleGenderChange = (e, value) => {
        setGender(value)
    }

    const handleDobChange = (selectedDate) => {
        const date = moment(selectedDate)
        setDob(date)
    }

    const handleGstChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setGst(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (dob === null || dob === '' || dob.isValid()) {
            setLoading(true)
            const data = {
                first_name: firstName,
                last_name: lastName,
                email: (email === null) ? '' : email,
                pan: (pan === null) ? '' : pan,
                aadhar: (aadhar === null) ? '' : aadhar,
                gender,
                dob: (dob === null || dob === '') ? '' : dateToString(dob),
                gst: (gst === null) ? '' : gst
            }
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/customers/' + customerId
            axios.put(url, data, headerConfig).then(response => {
                setLoading(false)
                setAlertType('success')
                setAlertMessage('Updated.')
                setShowAlert(true)
                // props.history.push('/dashboard/customers')
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        } else {
            setAlertType('error')
            setAlertMessage('Invalid date.')
            setShowAlert(true)
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Update Client</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/customers">
                        Client
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <div className="before">
                            <h2 className="form-heading">Update Client</h2>
                        </div>
                        <hr />
                        <form onSubmit={onSubmit}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                            >
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="fName"
                                        label="First Name"
                                        name="fName"
                                        value={firstName}
                                        inputProps={{ minLength: 3, maxLength: 50 }}
                                        onChange={handleFirstNameChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lName"
                                        label="Last Name"
                                        name="lName"
                                        value={lastName}
                                        inputProps={{ minLength: 3, maxLength: 50 }}
                                        onChange={handleLastNameChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MuiPhoneNumber
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        onlyCountries={['in']}
                                        defaultCountry={'in'}
                                        autoFormat={false}
                                        disableAreaCodes={true}
                                        disableCountryCode={true}
                                        disableDropdown={true}
                                        fullWidth
                                        id="number"
                                        label="Mobile Number"
                                        name="number"
                                        placeholder="Mobile number without +91"
                                        value={number}
                                        error={false}
                                        inputProps={{ minLength: 10, maxLength: 10 }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Autocomplete
                                        options={genders}
                                        autoHighlight
                                        value={gender}
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => <TextField {...params} label="Gender" required />}
                                        onChange={handleGenderChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        value={email}
                                        inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                                        onChange={handleEmailChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        name="pan"
                                        label="Pan Number"
                                        id="pan"
                                        value={pan}
                                        inputProps={{ minLength: 10, maxLength: 10 }}
                                        onChange={handlePanChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        name="aadhar"
                                        label="Aadhar Number"
                                        id="aadhar"
                                        value={aadhar}
                                        inputProps={{ minLength: 12, maxLength: 12 }}
                                        onChange={handleAadharChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            disableFuture
                                            fullWidth
                                            variant="inline"
                                            format="dd/MM/yyyy"
                                            id="date-picker-inline"
                                            label="Birth Date"
                                            value={dob}
                                            onChange={handleDobChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        name="gst"
                                        label="GST Number"
                                        id="gst"
                                        value={gst}
                                        inputProps={{ minLength: 15, maxLength: 15 }}
                                        onChange={handleGstChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Link to="/dashboard/customers">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                        >
                                            Cancel
                                        </Button>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        Update
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default UpdateCustomer
