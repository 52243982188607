import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import axios from 'axios'
import config from '../../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { Link } from 'react-router-dom'

import { dateToString, stringToDate, stringToTime, timeToString } from '../../../../functions'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddLead = (props) => {
    const token = localStorage.getItem('bsToken')
    const leadId = props.match.params.leadId
    const followupId = props.match.params.followupId

    const [users, setUsers] = useState([])
    const [assignee, setAssignee] = useState(null)
    const [followupDate, setFollowupDate] = useState(null)
    const [followupTime, setFollowupTime] = useState(null)
    const [nextFollowupDate, setNextFollowupDate] = useState(null)
    const [nextFollowupTime, setNextFollowupTime] = useState(null)
    const [followupRemarks, setFollowupRemarks] = useState('')

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getUsers = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/all'
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
            setAssignee(response.data[0])
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/followups/' + followupId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setAssignee(item.assignee)
            setFollowupDate(stringToDate(item.date))
            setFollowupTime(stringToTime(item.time))
            setNextFollowupDate(stringToDate(item.next_date))
            setNextFollowupTime(stringToTime(item.next_time))
            setFollowupRemarks(item.remarks)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getUsers()
        getData()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            assignee_id: assignee.id,
            next_date: dateToString(nextFollowupDate),
            next_time: timeToString(nextFollowupTime),
            date: dateToString(followupDate),
            time: timeToString(followupTime),
            remarks: followupRemarks
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/followups/' + followupId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAssigneeChange = (e, value) => {
        setAssignee(value)
    }

    const handleFollowupDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFollowupDate(date)
    }

    const handleFollowupTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFollowupTime(date)
    }

    const handleNextFollowupDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupDate(date)
    }

    const handleNextFollowupTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupTime(date)
    }

    const handleFollowupRemarksChange = (e) => {
        const value = e.target.value
        setFollowupRemarks(value)
    }

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Edit Lead Followup</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/crm/leads">
                        Leads
                    </MLink>
                    <MLink color="inherit" href={"/#/dashboard/crm/leads/" + leadId}>
                        View Lead
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Edit Lead Followup</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            className="mt-4"
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} lg={4}>
                                <Autocomplete
                                    options={users}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={assignee}
                                    renderInput={(params) => <TextField {...params} variant="outlined" label="Assign To" required />}
                                    onChange={handleAssigneeChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label="Date"
                                        value={followupDate}
                                        onChange={handleFollowupDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        id="time-picker"
                                        label="Followup Time"
                                        value={followupTime}
                                        onChange={handleFollowupTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    id="name"
                                    label="Remarks"
                                    name="name"
                                    value={followupRemarks}
                                    inputProps={{ minLength: 3, maxLength: 250, rows: 5 }}
                                    onChange={handleFollowupRemarksChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label="Next Date"
                                        value={nextFollowupDate}
                                        onChange={handleNextFollowupDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        id="time-picker"
                                        label="Next Followup Time"
                                        value={nextFollowupTime}
                                        onChange={handleNextFollowupTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <Link to={"/dashboard/crm/leads/" + leadId}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Back
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                // disabled={loading}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddLead
