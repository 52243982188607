import React, { useState, useEffect } from 'react'
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Snackbar,
    TextField,
    Tooltip
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import { Link } from 'react-router-dom'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'
import { dateFormate, dateToString, timeFormate, timeToString } from '../../../../functions'

import Table from '../../../../components/Table'
import axios from 'axios'
import config from '../../../../config'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Followups = (props) => {
    const token = localStorage.getItem('bsToken')
    const leadId = props.leadId
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)

    const [users, setUsers] = useState([])
    const [assignee, setAssignee] = useState(null)
    const [followupDate, setFollowupDate] = useState(moment())
    const [followupTime, setFollowupTime] = useState(moment())
    const [nextFollowupDate, setNextFollowupDate] = useState(moment())
    const [nextFollowupTime, setNextFollowupTime] = useState(moment())
    const [followupRemarks, setFollowupRemarks] = useState('')

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "assignee",
            label: "Ex. Name",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    return value.name
                }
            }
        },
        {
            name: "date",
            label: "Followup Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "time",
            label: "Followup Time",
            options: {
                sort: true,
                customBodyRender: timeFormate
            }
        },
        {
            name: "remarks",
            label: "Remarks",
            options: {
                sort: true,
            }
        },
        {
            name: "next_date",
            label: "Next Followup Date",
            options: {
                sort: false,
                customBodyRender: dateFormate
            }
        },
        {
            name: "next_time",
            label: "Next Followup Time",
            options: {
                sort: true,
                customBodyRender: timeFormate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        // setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/leads/' + leadId + '/followups'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const editUrl = leadId + '/followup/' + item.id + '/edit'
                const action = (
                    <div>
                        <Link to={editUrl}>
                            <Tooltip title="Edit">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Delete">
                            <IconButton onClick={() => handleClickOpen(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            // setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            // setLoading(false)
        })
    }

    const getUsers = () => {
        // setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/all'
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
            setAssignee(response.data[0])
            // setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            // setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        getUsers()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        // setLoading(true)
        const data = {
            assignee_id: assignee.id,
            next_date: dateToString(nextFollowupDate),
            next_time: timeToString(nextFollowupTime),
            date: dateToString(followupDate),
            time: timeToString(followupTime),
            remarks: followupRemarks
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/followups'
        axios.post(url, data, headerConfig).then(response => {
            // setLoading(false)
            setAlertType('success')
            setAlertMessage('Added.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            // setLoading(false)
        })
    }

    const deleteItem = (itemId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/followups/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClose = () => {
        setOpen(false)
        setItemId(null)
    }

    const handleAssigneeChange = (e, value) => {
        setAssignee(value)
    }

    const handleFollowupDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFollowupDate(date)
    }

    const handleFollowupTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setFollowupTime(date)
    }

    const handleNextFollowupDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupDate(date)
    }

    const handleNextFollowupTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupTime(date)
    }

    const handleFollowupRemarksChange = (e) => {
        const value = e.target.value
        setFollowupRemarks(value)
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Followup</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Followup ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Table
                title={'List Followups'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} lg={12}>
                                <div className="before mt-3">
                                    <h2 className="form-heading">New Followup</h2>
                                </div>
                                <hr />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <Autocomplete
                                    options={users}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={assignee}
                                    renderInput={(params) => <TextField {...params} variant="outlined" label="Assign To" required />}
                                    onChange={handleAssigneeChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label="Date"
                                        value={followupDate}
                                        onChange={handleFollowupDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        id="time-picker"
                                        label="Followup Time"
                                        value={followupTime}
                                        onChange={handleFollowupTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    id="name"
                                    label="Remarks"
                                    name="name"
                                    inputProps={{ minLength: 3, maxLength: 250, rows: 5 }}
                                    onChange={handleFollowupRemarksChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        format="dd/MM/yyyy"
                                        id="date-picker"
                                        label="Next Date"
                                        value={nextFollowupDate}
                                        onChange={handleNextFollowupDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardTimePicker
                                        required
                                        fullWidth
                                        inputVariant="outlined"
                                        id="time-picker"
                                        label="Next Followup Time"
                                        value={nextFollowupTime}
                                        onChange={handleNextFollowupTimeChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change time',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                // disabled={loading}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default Followups
