import React, { useEffect, useState } from 'react'
import {
    Button,
    Divider,
    Avatar,
    Menu,
    MenuItem
} from '@material-ui/core'
import { Container, Row, Col } from 'react-bootstrap'
import IconButton from '@material-ui/core/IconButton'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import Profile from '../assets/images/profile.png'

import { useHistory } from 'react-router-dom'

const OneSignal = window.OneSignal

const ProfileMenu = () => {
    const history = useHistory()
    const _user = JSON.parse(localStorage.getItem('bsUser'))
    const user = (_user === null) ? {} : _user
    const [anchorEl, setAnchorEl] = useState(null)
    const [image, setImage] = useState(Profile)

    useEffect(() => {
       setImage(user.url)
       // eslint-disable-next-line
    }, [_user])

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const logout = () => {
        OneSignal.push(function () {
            OneSignal.removeExternalUserId();
        });
        localStorage.removeItem('bsToken')
        localStorage.removeItem('bsUser')
        history.push('/login')
    }

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Avatar alt="Profile" src={image} />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Container>
                    <Row>
                        <Col xs={12} md={4} className="my-auto" style={{ padding: "unset" }}>
                            <img src={image} alt="Profile" style={{ width: '80px', height: '80px'}} />
                        </Col>
                        <Col xs={12} md={8}>
                            <h6>{user.first_name}</h6>
                            <div className="ago"><p>{user.email}</p></div>
                            <Button onClick={() => {
                                handleClose()
                                history.push('/dashboard/profile')
                            }} variant="contained" color="primary" size="small" className="btn-profile">View Profile</Button>
                        </Col>
                    </Row>
                </Container>
                <Divider className="mt-3" />
                <MenuItem onClick={logout} style={{ padding: "unset" }} className="mt-2"><PowerSettingsNewIcon fontSize="small" />&nbsp;Logout</MenuItem>
            </Menu>
        </div>
    )
}

export default ProfileMenu
