import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { dateToString } from '../../../functions'
import moment from 'moment'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdatePayment = (props) => {
    const [firstName, setFirstName] = useState(null)
    const [lastName, setLastName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [number, setNumber] = useState(null)
    const [pan, setPan] = useState(null)
    const [aadhar, setAadhar] = useState(null)
    // eslint-disable-next-line
    const [customer, setCustomer] = useState(null)
    const [dob, setDob] = useState(null)
    const [gst, setGst] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const token = localStorage.getItem('bsToken')
    // eslint-disable-next-line
    const [file, setFile] = useState(null)
    // eslint-disable-next-line
    const [fileData, setFileData] = useState(null)

    // eslint-disable-next-line
    const handleFileChange = (event) => {
        let files = event.target.files
        let file = files[0]
        let fileInfo = {
            name: file.name,
            type: file.type,
            size: (file.size / 1000000).toFixed(2) + ' MB',
            file: file,
        }
        const info = fileInfo.size + ' - ' + file.name
        setFileData(info)
        setFile(fileInfo)
    }

    // eslint-disable-next-line
    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    // eslint-disable-next-line
    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    // eslint-disable-next-line
    const handleEmailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    // eslint-disable-next-line
    const handlePasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPassword(value)
    }

    // eslint-disable-next-line
    const handleNumberChange = (value) => {
        setNumber(value)
    }

    const handlePanChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setPan(value)
    }

    // eslint-disable-next-line
    const handleAadharChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setAadhar(value)
    }

    // eslint-disable-next-line
    const handleCustomerChange = (e, value) => {
        setCustomer(value)
    }

    // eslint-disable-next-line
    const handleDobChange = (selectedDate) => {
        const date = moment(selectedDate)
        setDob(date)
    }

    // eslint-disable-next-line
    const handleGstChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setGst(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (dob === null || dob === '' || dob.isValid()) {
            setLoading(true)
            const data = {
                first_name: firstName,
                last_name: lastName,
                email: (email === null) ? '' : email,
                password,
                number,
                pan: (pan === null) ? '' : pan,
                aadhar: (aadhar === null) ? '' : aadhar,
                dob: (dob === null || dob === '') ? '' : dateToString(dob),
                gst: (gst === null) ? '' : gst
            }
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/customers'
            axios.post(url, data, headerConfig).then(response => {
                setLoading(false)
                setAlertType('success')
                setAlertMessage('Customer added.')
                setShowAlert(true)
                props.history.push('/dashboard/customers')
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        } else {
            setAlertType('error')
            setAlertMessage('Invalid date.')
            setShowAlert(true)
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/invoices">
                    Invoices
                </MLink>
                <MLink color="inherit" href="/#/dashboard/invoices/invoiceId/payments">
                    Payments
                </MLink>
                <Typography color="textPrimary">Update Payment</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="selectCustomer"
                                            label="Select Customer"
                                            id="selectCustomer"
                                            onChange={handlePanChange}
                                            inputProps={{ minLength: 3, maxLength: 50 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="invoiceNumber"
                                            label="Invoice Number"
                                            id="invoiceNumber"
                                            onChange={handlePanChange}
                                            inputProps={{ minLength: 3, maxLength: 50 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="amount"
                                            label="Amount"
                                            id="amount"
                                            onChange={handlePanChange}
                                            inputProps={{ minLength: 3, maxLength: 50 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ margin: "25px 0" }}>
                                <Link to="/dashboard/invoices/invoiceId/payments">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card >
        </div >
    )
}

export default UpdatePayment
