import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Chip,
    // Dialog,
    // DialogActions,
    // DialogContent,
    // DialogContentText,
    // DialogTitle,
    Drawer,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    makeStyles,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core'

import Autocomplete from '@material-ui/lab/Autocomplete'

import { createMuiTheme, useTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core'

import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'

import AddIcon from '@material-ui/icons/Add'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import ClearIcon from '@material-ui/icons/Clear'

import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

import { Link, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import MuiAlert from '@material-ui/lab/Alert'
import { dateFormate, dateToString } from '../../../functions'

import TuneIcon from '@material-ui/icons/Tune'

const useStyles = makeStyles((theme) => ({
    filter: {
        padding: theme.spacing(4),
        width: '450px',
    },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const statusStyle = (slug) => {
    if (slug === 'cold') {
        return <Chip size="small" label="COLD" className="chip-cold" />
    } else if (slug === 'warm') {
        return <Chip size="small" label="WARM" className="chip-warm" />
    } else if (slug === 'hot') {
        return <Chip size="small" label="HOT" className="chip-hot" />
    } else if (slug === 'received') {
        return <Chip size="small" label="ORDER RECEIVED" className="chip-received" />
    } else if (slug === 'loss') {
        return <Chip size="small" label="ORDER LOSS" className="chip-loss" />
    } else {
        return ''
    }
}

const leadStatues = [
    {
        name: 'Cold',
        id: 'ed4d01a6-a4fc-4e78-b3c0-c93c4f54017a'
    },
    {
        name: 'Hot',
        id: 'c9277437-f845-4f37-b69e-bec045d5bdc7'
    },
    {
        name: 'Warm',
        id: 'a871a62e-c532-4264-a36b-f9d005cc5854'
    },
    {
        name: 'Order Received',
        id: '883e3d84-0aa0-4359-ad67-ca362ce7039a'
    },
    {
        name: 'Order Loss',
        id: '938998f0-f386-48aa-b847-5667d4b468c0'
    }
]

const branches = [
    'Bhuj',
    'Mankuva',
    'Rakjot'
]

const ListLeads = () => {
    const { search } = useLocation()
    const parsed = queryString.parse(search)
    const classes = useStyles()
    const token = localStorage.getItem('bsToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [textSearch, setTextSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    // const [itemId, setItemId] = useState(null)

    const [open, setOpen] = useState(false)

    const [type, setType] = useState('all')

    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)

    // const [openDelete, setOpenDelete] = useState(false)
    const [leadSources, setLeadSources] = useState([])
    const [services, setServices] = useState([])
    const [users, setUsers] = useState([])

    const [branch, setBranch] = useState(null)
    const [service, setService] = useState(null)
    const [source, setSource] = useState(null)
    const [status, setStatus] = useState(null)
    const [assignee, setAssignee] = useState(null)
    const [creator, setCreator] = useState(null)


    const theme = useTheme()
    const tableTheme = createMuiTheme({
        palette: {
            type: theme.palette.type
        },
        overrides: {
            MuiTableCell: {
                root: {  //This can be referred from Material UI API documentation. 
                    padding: '5px 16px 5px 18px',
                    fontSize: "12px"
                },
            },
        },
    })

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        },
        {
            name: "lead_no",
            label: "Lead No",
            options: {
                sort: true,
            }
        },
        {
            name: "date",
            label: "Lead Date",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value === '') ? '' : dateFormate(value)
                }
            }
        },
        {
            name: "state",
            label: "State",
            options: {
                sort: false,
            }
        },
        {
            name: "city",
            label: "City",
            options: {
                sort: false,
            }
        },
        {
            name: "creator",
            label: "Gen. By",
            options: {
                sort: false,
            }
        },
        {
            name: "assignee",
            label: "Assigned To",
            options: {
                sort: false,
            }
        },
        {
            name: "company",
            label: "Company",
            options: {
                sort: true,
            }
        },
        {
            name: "person_name",
            label: "Person Name",
            options: {
                sort: true,
            }
        },
        {
            name: "person_number",
            label: "Mobile No",
            options: {
                sort: true,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: statusStyle
            }
        }
    ]

    const getSources = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/lead-sources/all'
        axios.get(url, headerConfig).then(response => {
            setLeadSources(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getServices = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/services/all'
        axios.get(url, headerConfig).then(response => {
            setServices(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getUsers = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/all'
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getList = (type = 'all', start = 0, limit = 10, sortBy = 'all', order = 'all', textSearch = 'all') => {
        setLoading(true)
        const _startDate = (startDate === null) ? 'all' : dateToString(startDate)
        const _endDate = (endDate === null) ? 'all' : dateToString(endDate)
        const _branch = (branch === null) ? 'all' : branch
        const _service = (service === null) ? 'all' : service.id
        const _source = (source === null) ? 'all' : source.id
        const _status = (status === null) ? 'all' : status.id
        const _creator = (creator === null) ? 'all' : creator.id
        const _assignee = (assignee === null) ? 'all' : assignee.id
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            textSearch,
            type,
            start_date: _startDate,
            end_date: _endDate,
            branch: _branch,
            service: _service,
            source: _source,
            lead_status: _status,
            creator: _creator,
            assignee: _assignee
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/leads'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = 'leads/' + item.id
                const editUrl = 'leads/' + item.id + '/edit'
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={viewUrl}>
                                <Tooltip title="View">
                                    <IconButton>
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Link to={editUrl}>
                                <Tooltip title="Edit">
                                    <IconButton>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            {/* <Tooltip title="Delete Lead">
                            <IconButton onClick={() => handleClickOpenDelete(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> */}
                        </ButtonGroup>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        const _type = parsed.type
        setType(_type)
        getList(_type)
        getSources()
        getServices()
        getUsers()
        // eslint-disable-next-line
    }, [parsed.type])

    const handleStartDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        if (endDate === null) {
            setEndDate(date)
        }
        else if (date.isAfter(endDate)) {
            setEndDate(date)
        }
        setStartDate(date)
    }

    const handleEndDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        if (startDate === null) {
            setStartDate(date)
        }
        else if (date.isBefore(startDate)) {
            setStartDate(date)
        }
        setEndDate(date)
    }

    const changePage = (page) => {
        const start = limit * (page)
        getList(type, start, limit, sortBy, order, textSearch)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(type, start, limit, sortBy, order, textSearch)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(type, start, limit, sortBy, order, textSearch)
        setLimit(limit)
    }

    const onSearch = (textSearch) => {
        getList(type, start, limit, sortBy, order, textSearch)
        setTextSearch(textSearch)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'textSearch':
                const textSearch = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(textSearch)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const toggleDrawer = () => {
        setOpen(!open)
    }

    const handleBranchChange = (e, item) => {
        setBranch(item)
    }

    const handleServiceChange = (e, item) => {
        setService(item)
    }

    const handleSourceChange = (e, item) => {
        setSource(item)
    }

    const handleStatusChange = (e, item) => {
        setStatus(item)
    }

    const handleCreatorChange = (e, item) => {
        setCreator(item)
    }

    const handleAssigneeChange = (e, item) => {
        setAssignee(item)
    }

    // const deleteItem = (itemId) => {
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             token
    //         }
    //     }

    //     const url = config.url + '/leads/' + itemId
    //     axios.delete(url, headerConfig).then(response => {
    //         setAlertType('success')
    //         setAlertMessage('Deleted.')
    //         setShowAlert(true)
    //         setOpenDelete(false)
    //         getList()
    //     }).catch(error => {
    //         let message = 'Try after some time.'
    //         if (error.response) {
    //             if (error.response.status === 401) {
    //                 message = error.response.data.detail
    //             }
    //             console.log(error.response)
    //         } else if (error.request) {
    //             console.log(error.request)
    //         } else {
    //             console.log(error)
    //         }
    //         setAlertType('error')
    //         setAlertMessage(message)
    //         setShowAlert(true)
    //     })
    // }

    // const handleClickOpenDelete = (itemId) => {
    //     setOpenDelete(true)
    //     setItemId(itemId)
    // }

    // const handleClose = () => {
    //     setOpenDelete(false)
    //     setItemId(null)
    // }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Leads</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="leads/add" className="ml-2"><Button variant="contained" color="primary"><AddIcon />Add Lead</Button></Link>
                        <Link to="leads/deleted" className="ml-2"><Button variant="contained" color="secondary"><DeleteIcon />Deleted Leads</Button></Link>
                        <Link to="#" onClick={toggleDrawer} className="ml-2"><Button variant="contained" color="primary"><TuneIcon />Filters</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Drawer anchor='right' open={open} onClose={toggleDrawer}>
                <div className={classes.filter} >
                    <Grid container
                        direction="column"
                        spacing={2}
                    >
                        <Grid item >
                            <Grid container
                                direction="row"
                                justify="flex-end"
                                alignItems="flex-start"
                            >
                                <Grid item >
                                    <Tooltip title="Close"><IconButton onClick={toggleDrawer}><CloseIcon /></IconButton></Tooltip>
                                    &nbsp;
                                    <Button variant="contained" color="primary" onClick={() => {
                                        getList(parsed.type)
                                        toggleDrawer()
                                    }}>
                                        Apply
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={branches}
                                autoHighlight
                                getOptionLabel={(option) => option}
                                value={branch}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Branch" />}
                                onChange={handleBranchChange}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={services}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={service}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Service" />}
                                onChange={handleServiceChange}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={leadSources}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={source}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Source" />}
                                onChange={handleSourceChange}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={leadStatues}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={status}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Status" />}
                                onChange={handleStatusChange}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={users}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={creator}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Generated by" />}
                                onChange={handleCreatorChange}
                            />
                        </Grid>
                        <Grid item>
                            <Autocomplete
                                options={users}
                                autoHighlight
                                getOptionLabel={(option) => option.name}
                                value={assignee}
                                renderInput={(params) => <TextField {...params} variant="outlined" label="Assigned to" />}
                                onChange={handleAssigneeChange}
                            />
                        </Grid>
                        <Grid item >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    id="date-picker"
                                    label="From Date"
                                    value={startDate}
                                    onChange={handleStartDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    fullWidth
                                    inputVariant="outlined"
                                    format="dd/MM/yyyy"
                                    id="date-picker"
                                    label="To Date"
                                    value={endDate}
                                    onChange={handleEndDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            {(startDate !== null && endDate !== null) ? (
                                <Tooltip title="Clear Date" >
                                    <IconButton
                                        onClick={() => {
                                            setStartDate(null)
                                            setEndDate(null)
                                        }}
                                    >
                                        <ClearIcon color="error" />
                                    </IconButton>
                                </Tooltip>) : ''}
                        </Grid>
                    </Grid>
                </div>
            </Drawer>
            {/* <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Lead ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} variant="outlined" disabled={loading} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog> */}
            <br />
            <div className="entry-div">
                {progress}
                <ThemeProvider theme={tableTheme}>
                    <Table
                        title={'List Leads'}
                        serverSide={true}
                        count={count}
                        columns={columns}
                        data={tableData}
                        onTableChange={handleTableChange}
                        rowsPerPage={limit}
                    />
                </ThemeProvider>
                {progress}
            </div>
        </div>
    )
}

export default ListLeads
