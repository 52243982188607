import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'
import { dateFormate } from '../../../functions'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListMembers = (props) => {
    const token = localStorage.getItem('bsToken')
    const customerId = props.match.params.customerId
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [name, setName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [memberId, setMemberId] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "client_id",
            label: "Client Id",
            options: {
                sort: true,
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
            }
        },
        {
            name: "number",
            label: "Number",
            options: {
                sort: true,
            }
        },
        {
            name: "pan",
            label: "Pan Number",
            options: {
                sort: true,
            }
        },
        {
            name: "aadhar",
            label: "Aadhar Number",
            options: {
                sort: true,
            }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                sort: true,
            }
        },
        {
            name: "dob",
            label: "Birth Date",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value === '') ? '' : dateFormate(value)
                }
            }
        },
        {
            name: "relation",
            label: "Relation",
            options: {
                sort: false,
            }
        },
        {
            name: "gst",
            label: "GST Number",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/customers/' + customerId + '/members'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(member => {
                const editUrl = '/dashboard/customers/' + customerId + '/members/' + member.id
                const listFolders = '/dashboard/customers/' + member.id + '/folders'
                // const listDocuments = '/dashboard/customers/' + customerId + '/members/' + member.id
                member.action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={listFolders}>
                                <Tooltip title="View Folders">
                                    <IconButton>
                                        <FolderOpenIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            {/* <Link to={listDocuments}>
                            <Tooltip title="List Documents">
                                <IconButton>
                                    <InsertDriveFileIcon />
                                </IconButton>
                            </Tooltip>
                        </Link> */}
                            <Link to={editUrl}>
                                <Tooltip title="Edit Member">
                                    <IconButton>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Tooltip title="Delete Member">
                                <IconButton onClick={() => handleClickOpen(member.id)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                )
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getUser = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const customer = response.data
            const name = customer.first_name + ' ' + customer.last_name + '\'s Members'
            setName(name)
            getList()
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteUser = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/members/' + memberId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Member Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (memberId) => {
        setOpen(true)
        setMemberId(memberId)
    }

    const handleClose = () => {
        setOpen(false)
        setMemberId()
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Members</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/customers">
                                Clients
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="members/add"><Button variant="contained" color="primary"><AddIcon />Add Member</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Member"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Member ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteUser()} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="entry-div">
                {progress}
                <Table
                    title={name}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                {progress}
            </div>
        </div>
    )
}

export default ListMembers
