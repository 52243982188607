import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'

import {
    Box,
    Button,
    Container,
    CssBaseline,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'

import isEmail from 'validator/lib/isEmail'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import config from '../../config.json'
import axios from 'axios'
import Logo from '../../assets/images/Logo.png'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <MLink color="inherit" href="#">
                BizSmart
            </MLink>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.primary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ForgotPassword(props) {
    const classes = useStyles();
    const [disabled, setDisabled] = useState(false)
    const [error, setError] = useState(null)
    const [email, setEmail] = useState(null)
    const [otp, setOtp] = useState(null)
    const [newPassword, setNewPassword] = useState(null)
    const [cnfNewPassword, setCnfNewPassword] = useState(null)
    const showError = (<span style={{ color: 'red' }}>{error}</span>)
    const [status, setStatus] = useState(false)

    useEffect(() => {
        const token = localStorage.getItem('bsToken')
        if (token !== null) {
            var location = '/dashboard'
            props.history.push(location)
        }
        // eslint-disable-next-line
    }, [])

    const emailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
        if (!isEmail(value)) {
            setError('Invalid email')
            setDisabled(true)
        }
        else {
            setError(null)
            setDisabled(false)
        }
    }

    const getOtp = (email) => {
        setDisabled(true)
        const data = {
            email
        }
        const url = config.url + '/forgot-password'
        axios.post(url, data).then(response => {
            setStatus(true)
            setDisabled(false)
        }).catch(error => {
            if (error.response) {
                setError(error.response.data.detail)
            } else if (error.request) {
                console.log(error.request)
                setError('Try after some time.')
            } else {
                console.log(error)
                setError('Try after some time.')
            }
            setDisabled(false)
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        document.getElementById('verify').reset()
        getOtp(email)
    }

    const handleReset = (e) => {
        e.preventDefault()
        if (newPassword !== cnfNewPassword) {
            setError('Passwords are not same')
        }
        else {
            setDisabled(true)
            const url = config.url + '/confirm-forgot-password'
            const data = {
                email,
                otp,
                password: newPassword
            }
            axios.post(url, data).then(response => {
                setDisabled(false)
                props.history.push('/login')
            }).catch(error => {
                if (error.response) {
                    setError(error.response.data.detail)
                } else if (error.request) {
                    console.log(error.request);
                    setError('Try after some time')
                } else {
                    console.log('Error', error.message);
                    setError('Try after some time')
                }
                setDisabled(false)
            })
        }
    }

    const otpChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setOtp(value)
        setError(null)
    }

    const newPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNewPassword(value)
        setError(null)
    }

    const cnfNewPasswordChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setCnfNewPassword(value)
        setError(null)
    }

    const checkEmailForm = (
        <form id="verify" className={classes.form} onSubmit={handleSubmit} >
            <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                inputProps={{ minLength: 5, maxLength: 50, type: 'email' }}
                onChange={emailChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2">
                        Login
                    </MLink>
                </Grid>
                <Grid item>
                    {showError}
                </Grid>
            </Grid>
        </form>
    )

    const resetPasswordForm = (
        <form id="reset" className={classes.form} onSubmit={handleReset} >
            <TextField
                required
                variant="outlined"
                margin="normal"
                fullWidth
                id="otp"
                label="OTP"
                name="otp"
                inputProps={{ minLength: 6, maxLength: 6, title: "Numbers only", pattern: "[0-9]{6}" }}
                onChange={otpChange}
                autoFocus
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={newPasswordChange}
            />
            <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm Password"
                type="password"
                id="password"
                inputProps={{ minLength: 6, maxLength: 50 }}
                onChange={cnfNewPasswordChange}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disabled}
                className={classes.submit}
            >
                Submit
            </Button>
            <Grid container>
                <Grid item xs>
                    <MLink href="/#/login" variant="body2">
                        Login
                    </MLink>
                </Grid>
                <Grid item>
                    {showError}
                </Grid>
            </Grid>
        </form>
    )

    return (
        <div className="bg-auth">
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <img src={Logo} alt="Logo" className="img-fluid" />
                    <Typography component="h1" variant="h5" className="mb-4">
                        Forgot Password
                    </Typography>
                    {status ? resetPasswordForm : checkEmailForm}
                </div>
                <Box mt={8} className="pb-5">
                    <Copyright />
                </Box>
            </Container>
        </div>
    );
}
