import React, { useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    Link as MLink,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddDocumentType = (props) => {
    const token = localStorage.getItem('bsToken')
    const [name, setName] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')


    const nameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            name
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/document-types'
        axios.post(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Document types added.')
            setShowAlert(true)
            props.history.push('/dashboard/document-types')
        }).catch(error => {
            let message
            if (error.response) {
                console.log(error.response)
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else if (error.response.status === 409) {
                    message = error.response.data.detail
                }
                else {
                    message = error.response.data.detail[0].msg
                }
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })

    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add Document Type</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/document-types">
                        Document Types
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Add Document Type</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Document Type Name"
                                    name="name"
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/document-types">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddDocumentType
