import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import FormControlLabel from '@material-ui/core/FormControlLabel'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddMember = (props) => {
    const token = localStorage.getItem('bsToken')
    const customerId = props.match.params.customerId
    const folderId = props.match.params.folderId
    const documentId = props.match.params.documentId
    const [name, setName] = useState('')
    const [title, setTitle] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [isPrivate, setIsPrivate] = useState(false)

    const getUser = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const customer = response.data
            const name = customer.first_name + ' ' + customer.last_name
            setName(name)
            getDocument()
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getDocument = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/folders/' + folderId + '/documents/' + documentId
        axios.get(url, headerConfig).then(response => {
            const document = response.data
            setTitle(document.title)
            setIsPrivate(document.is_private)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    const handleChange = (event) => {
        setIsPrivate(event.target.checked)
    }

    const handleTitleChange = (e) => {
        var value = e.target.value
        setTitle(value)
    }


    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            title,
            is_private: isPrivate
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/folders/' + folderId + '/documents/' + documentId
        axios.put(url, data, headerConfig).then(response => {
            setLoading(false)
            setAlertType('success')
            setAlertMessage('Document updated.')
            setShowAlert(true)
            // props.history.push('/dashboard/customers/' + customerId + '/members')
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                } else if (error.response.status === 422) {
                    message = error.response.data.detail
                } else {
                    console.log(error.response)
                }
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Edit Document</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/customers">
                        Clients
                    </MLink>
                    <MLink color="inherit" href={'/#/dashboard/customers/' + customerId + '/folders/' + folderId + '/documents'}>
                        Documents
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={4}>
                                <div className="before">
                                    <h2 className="form-heading">Client : {name}</h2>
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <br />
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12}>
                                <div className="before">
                                    <h2 className="form-heading">Edit Document</h2>
                                </div>
                                <hr />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="dName"
                                    label="Document Title"
                                    name="fName"
                                    value={title}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={isPrivate}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Is Private"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Link to={'/dashboard/customers/' + customerId + '/folders/' + folderId + '/documents'}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddMember
