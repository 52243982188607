import React, { useEffect, useRef, useState } from 'react'
import {
    Breadcrumbs,
    // Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import config from '../../config'
import Autocomplete from '@material-ui/lab/Autocomplete'
// import AddIcon from '@material-ui/icons/Add'
// import { Link } from 'react-router-dom'

import Invoices from './Invoices'
import Payments from './Payments'


const PaymentReports = (props) => {
    const invoiceRef = useRef(null)
    const paymentRef = useRef(null)
    const token = localStorage.getItem('bsToken')
    const [customers, setCustomers] = useState([])
    // eslint-disable-next-line
    const [customerId, setCustomerId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalPending, setTotalPending] = useState(0)

    const getCustomers = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/invoices/customers'
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomers()
        // eslint-disable-next-line
    }, [])

    const getTotal = (customerId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/total'
        axios.get(url, headerConfig).then(response => {
            const amount = response.data
            setTotalAmount(amount.total_amount)
            setTotalPaid(amount.total_paid)
            setTotalPending(amount.total_pending)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const handleCustomerChange = (e, value) => {
        if (value === null) {
            setCustomerId(null)
            invoiceRef.current.updateInvoiceTable(null)
            paymentRef.current.updatePaymentsTable(null)
            setTotalAmount(0)
            setTotalPaid(0)
            setTotalPending(0)
        } else {
            const customerId = value.id
            setCustomerId(customerId)
            getTotal(customerId)
            invoiceRef.current.updateInvoiceTable(customerId)
            paymentRef.current.updatePaymentsTable(customerId)
        }
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Payments</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        {/* <Link to="invoices/add"><Button variant="contained" color="primary"><AddIcon />Add New Invoice</Button></Link> */}
                    </Grid>
                </Grid>
            </div>
            <br />
            {progress}
            <Card className="entry-div">
                <CardContent>
                    {/* <h6 class="MuiTypography-root MUIDataTableToolbar-titleText-119 MuiTypography-h6">Payment Details</h6>
                    <br /> */}
                    <Grid container
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        spacing={1}
                    >
                        <Grid item xs={12}>
                            <div className="before">
                                <h2 className="form-heading">Select Customer</h2>
                            </div>
                            <hr />
                        </Grid>
                        <Grid item xs={4}>
                            <Autocomplete
                                options={customers}
                                autoHighlight
                                required
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Customer" required />}
                                onChange={handleCustomerChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <br />
            <Grid container
                direction="row"
                justify="space-between"
                spacing={1}
            >
                <Grid item xs={6}><Invoices ref={invoiceRef} /></Grid>
                <Grid item xs={6}><Payments ref={paymentRef} /></Grid>
            </Grid>
            <br />
            <Card>
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Total Payment To Receive</h2>
                    </div>
                    <hr />
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Invoice Amount</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalAmount}</b></p>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Paid Amount</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalPaid}</b></p>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Pending Payment</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalPending}</b></p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default PaymentReports
