import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from "react-router-dom";

import {
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip
} from '@material-ui/core'

import ListIcon from '@material-ui/icons/List'
import HomeIcon from '@material-ui/icons/Home'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import SettingsIcon from '@material-ui/icons/Settings'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import PersonIcon from '@material-ui/icons/Person'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import BookIcon from '@material-ui/icons/Book'
import ReceiptIcon from '@material-ui/icons/Receipt'
import PaymentIcon from '@material-ui/icons/Payment'
import DescriptionIcon from '@material-ui/icons/Description'
import LiveHelpIcon from '@material-ui/icons/LiveHelp'
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber'
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel'
import UpdateIcon from '@material-ui/icons/Update'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import NoteAddIcon from '@material-ui/icons/NoteAdd'
import DateRangeIcon from '@material-ui/icons/DateRange'
import EventAvailableIcon from '@material-ui/icons/EventAvailable'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown';

import Logo from '../assets/images/Logo.png'

import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../config'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    nested: {
        paddingLeft: theme.spacing(2),
        minWidth: '40px'
    },
}));

const SideNav = () => {
    const token = localStorage.getItem('bsToken')
    const classes = useStyles()
    const [crmToggle, setCrmToggle] = useState(false)
    const [settingToggle, setSettingToggle] = useState(false)
    const [operations, setOperations] = useState([])
    const [menu, setMenu] = useState([])
    let location = useLocation().pathname;
    let queryLocation = useLocation().search;
    const [urlDashboard, setUrlDashboard] = useState('')
    const [urlCustomers, setUrlCustomers] = useState('')
    const [urlNewsCategory, setUrlNewsCategory] = useState('')
    const [urlNews, setUrlNews] = useState('')
    const [urlInvoices, setUrlInvoices] = useState('')
    const [urlPayments, setUrlPayments] = useState('')
    const [urlDocumentType, setUrlDocumentType] = useState('')
    const [urlFAQ, setUrlFAQ] = useState('')
    const [urlTickets, setUrlTickets] = useState('')
    const [urlTicketTypes, setUrlTicketTypes] = useState('')
    const [urlAddLead, setUrlAddLead] = useState('')
    const [urlLeads, setUrlLeads] = useState('')
    const [urlPendingFollowups, setUrlPendingFollowups] = useState('')
    const [urlTodaysFollowups, setUrlTodaysFollowups] = useState('')
    const [urlReceivedOrders, setUrlReceivedOrders] = useState('')
    const [urlLossOrders, setUrlLossOrders] = useState('')
    const [urlCrmCustomers, setUrlCrmCustomers] = useState('')
    const [urlServices, setUrlServices] = useState('')
    const [urlSources, setUrlSources] = useState('')
    const [urlUsers, setUrlUsers] = useState('')
    const [urlRoles, setUrlRoles] = useState('')
    const [urlBanners, setUrlBanners] = useState('')
    const [urlVersion, setUrlVersion] = useState('')
    const [urlProfile, setUrlProfile] = useState('')

    const getOperations = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/operations'
        axios.get(url, headerConfig).then(response => {
            setOperations(response.data.operations)
            setMenu(response.data.menu)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getOperations()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        activeUnset()
        checkURL()
        // eslint-disable-next-line
    }, [location])

    const checkOperation = (item) => {
        const status = operations.includes(item)
        return status
    }

    const checkMenu = (operation) => {
        const status = menu.includes(operation)
        return status
    }

    const activeUnset = () => {
        setUrlDashboard('')
        setUrlCustomers('')
        setUrlNewsCategory('')
        setUrlNews('')
        setUrlInvoices('')
        setUrlPayments('')
        setUrlDocumentType('')
        setUrlFAQ('')
        setUrlTickets('')
        setUrlTicketTypes('')
        setUrlAddLead('')
        setUrlLeads('')
        setUrlPendingFollowups('')
        setUrlTodaysFollowups('')
        setUrlReceivedOrders('')
        setUrlLossOrders('')
        setUrlCrmCustomers('')
        setUrlServices('')
        setUrlSources('')
        setUrlUsers('')
        setUrlRoles('')
        setUrlBanners('')
        setUrlVersion('')
        setUrlProfile('')
    }

    const checkURL = () => {
        if (location.includes("crm") && location.includes("customers")) {
            setUrlCrmCustomers('active');
        } else if (location.includes("customers")) {
            setUrlCustomers('active');
        } else if (location.includes("news-categories")) {
            setUrlNewsCategory('active');
        } else if (location.includes("news")) {
            setUrlNews('active');
        } else if (location.includes("invoices")) {
            setUrlInvoices('active');
        } else if (location.includes("payments")) {
            setUrlPayments('active');
        } else if (location.includes("document-types")) {
            setUrlDocumentType('active');
        } else if (location.includes("faqs")) {
            setUrlFAQ('active');
        } else if (location.includes("tickets")) {
            setUrlTickets('active');
        } else if (location.includes("ticket-types")) {
            setUrlTicketTypes('active');
        } else if (location.includes("crm") && location.includes("leads") && location.includes("add")) {
            setUrlAddLead('active');
        } else if (location.includes("crm") && queryLocation.includes("?type=received")) {
            setUrlReceivedOrders('active');
        } else if (location.includes("crm") && queryLocation.includes("?type=loss")) {
            setUrlLossOrders('active');
        } else if (location.includes("crm") && location.includes("leads")) {
            setUrlLeads('active');
        } else if (location.includes("crm") && location.includes("followups") && location.includes("pending")) {
            setUrlPendingFollowups('active');
        } else if (location.includes("crm") && location.includes("followups") && location.includes("today")) {
            setUrlTodaysFollowups('active');
        } else if (location.includes("crm") && location.includes("services")) {
            setUrlServices('active');
        } else if (location.includes("crm") && location.includes("sources")) {
            setUrlSources('active');
        } else if (location.includes("users")) {
            setUrlUsers('active');
        } else if (location.includes("roles")) {
            setUrlRoles('active');
        } else if (location.includes("banners")) {
            setUrlBanners('active');
        } else if (location.includes("version")) {
            setUrlVersion('active');
        } else if (location.includes("profile")) {
            setUrlProfile('active');
        } else {
            setUrlDashboard('active');
        }

        if (location.includes("crm")) {
            setCrmToggle(true)
        }
        if (location.includes("settings")) {
            setSettingToggle(true)
        }
    }

    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
        >
            <div className="text-center">
                <img src={Logo} alt="Logo" className="img-fluid menu-logo" />
            </div>

            <Link to="/dashboard" className={urlDashboard}>
                <ListItem button>
                    <ListItemIcon><Tooltip title="Dashboard"><HomeIcon /></Tooltip></ListItemIcon>
                    <ListItemText primary="Dashboard" />
                </ListItem>
            </Link>

            <ListItem button onClick={() => setCrmToggle(!crmToggle)}>
                <ListItemIcon>
                    <Tooltip title="CRM"><SupervisedUserCircleIcon /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="CRM" />
                {crmToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={crmToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('Add Lead') ? (
                        <Link to='/dashboard/crm/leads/add' className={urlAddLead}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Add Lead"><NoteAddIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Add Lead" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Leads') ? (
                        <Link to='/dashboard/crm/leads' className={urlLeads}>
                            <ListItem button className={classes.nested} onClick={() => {
                                setUrlLossOrders('');
                                setUrlReceivedOrders('')
                                setUrlLeads('active');

                            }}>
                                <ListItemIcon>
                                    <Tooltip title="View All Leads"><FormatListBulletedIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="View All Leads" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Leads') ? (
                        <Link to='/dashboard/crm/followups/pending' className={urlPendingFollowups}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Pending Followups"><DateRangeIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Pending Followups" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Leads') ? (
                        <Link to='/dashboard/crm/followups/today' className={urlTodaysFollowups}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Today's Followups"><EventAvailableIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Today's Followups" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Leads') ? (
                        <Link to='/dashboard/crm/leads?type=received' className={urlReceivedOrders}>
                            <ListItem button className={classes.nested} onClick={() => {
                                setUrlLeads('');
                                setUrlLossOrders('');
                                setUrlReceivedOrders('active')

                            }}>
                                <ListItemIcon>
                                    <Tooltip title="Received Orders"><TrendingUpIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Received Orders" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Leads') ? (
                        <Link to='/dashboard/crm/leads?type=loss' className={urlLossOrders}>
                            <ListItem button className={classes.nested} onClick={() => {
                                setUrlLeads('');
                                setUrlReceivedOrders('')
                                setUrlLossOrders('active');

                            }}>
                                <ListItemIcon>
                                    <Tooltip title="Loss Orders"><TrendingDownIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Loss Orders" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    <Divider />

                    {checkMenu('CRM Customers') ? (
                        <Link to='/dashboard/crm/customers' className={urlCrmCustomers}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon><Tooltip title="Customers"><PeopleAltIcon /></Tooltip></ListItemIcon>
                                <ListItemText primary="Customers" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    <Divider />

                    {checkOperation('List Services') ? (
                        <Link to='/dashboard/crm/services' className={urlServices}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="List Services"><FormatListBulletedIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="List Services" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Lead Sources') ? (
                        <Link to='/dashboard/crm/sources' className={urlSources}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="List Lead Sources"><FormatListBulletedIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="List Lead Sources" />
                            </ListItem>
                        </Link>
                    ) : ('')}
                </List>
            </Collapse>

            <Divider />

            {checkMenu('Customers') ? (
                <Link to='/dashboard/customers' className={urlCustomers}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Clients"><PeopleAltIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Clients" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('News Categories') ? (
                <Link to='/dashboard/news-categories' className={urlNewsCategory}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="News Categories"><BookIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="News Categories" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('News') ? (
                <Link to='/dashboard/news' className={urlNews}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="News"><BookIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="News" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Invoices') ? (
                <Link to='/dashboard/invoices' className={urlInvoices}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Invoices"><ReceiptIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Invoices" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Payments') ? (
                <Link to='/dashboard/payments' className={urlPayments}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Payments"><PaymentIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Payments" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Document Types') ? (
                <Link to='/dashboard/document-types' className={urlDocumentType}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Document Types"><DescriptionIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Document Types" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('FAQs') ? (
                <Link to='/dashboard/faqs' className={urlFAQ}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="FAQs"><LiveHelpIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="FAQs" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Tickets') ? (
                <Link to='/dashboard/tickets' className={urlTickets}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Tickets"><ConfirmationNumberIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Tickets" />
                    </ListItem>
                </Link>
            ) : ('')}

            {checkMenu('Ticket Types') ? (
                <Link to='/dashboard/ticket-types' className={urlTicketTypes}>
                    <ListItem button>
                        <ListItemIcon><Tooltip title="Ticket Types"><ConfirmationNumberIcon /></Tooltip></ListItemIcon>
                        <ListItemText primary="Ticket Types" />
                    </ListItem>
                </Link>
            ) : ('')}

            <ListItem button onClick={() => setSettingToggle(!settingToggle)}>
                <ListItemIcon>
                    <Tooltip title="Settings"><SettingsIcon /></Tooltip>
                </ListItemIcon>
                <ListItemText primary="Settings" />
                {settingToggle ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={settingToggle} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {checkOperation('List Users') ? (
                        <Link to='/dashboard/settings/users' className={urlUsers}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Users"><SupervisorAccountIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Roles') ? (
                        <Link to='/dashboard/settings/roles' className={urlRoles}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Roles"><ListIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Roles" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('List Banners') ? (
                        <Link to='/dashboard/settings/banners' className={urlBanners}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Banners"><ViewCarouselIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="Banners" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    {checkOperation('Update Version') ? (
                        <Link to='/dashboard/settings/version' className={urlVersion}>
                            <ListItem button className={classes.nested}>
                                <ListItemIcon>
                                    <Tooltip title="Update App Version"><UpdateIcon /></Tooltip>
                                </ListItemIcon>
                                <ListItemText primary="App Version" />
                            </ListItem>
                        </Link>
                    ) : ('')}

                    <Link to='/dashboard/profile' className={urlProfile}>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon>
                                <Tooltip title="My Profile"><PersonIcon /></Tooltip>
                            </ListItemIcon>
                            <ListItemText primary="My Profile" />
                        </ListItem>
                    </Link>
                </List>
            </Collapse>
        </List>
    )
}

export default SideNav
