import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'
import AddIcon from '@material-ui/icons/Add'
import FolderOpenIcon from '@material-ui/icons/FolderOpen'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const OpenFolder = (props) => {
    const token = localStorage.getItem('bsToken')
    const customerId = props.match.params.customerId
    const [currnetDir, setCurrnetDir] = useState('')
    // const parentId = props.match.params.folderId
    const [parentId, setParentId] = useState(props.match.params.folderId)
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [name, setName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    // const [folderName, setFolderName] = useState(null)
    const [folderId, setFolderId] = useState(null)
    const [tree, setTree] = useState([])

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Name",
            options: {
                sort: true,
            }
        },
        {
            name: "folders",
            label: "Sub Folders",
            options: {
                sort: false,
            }
        },
        {
            name: "documents",
            label: "Documents",
            options: {
                sort: false,
            }
        },
        {
            name: "order_index",
            label: "Index",
            options: {
                sort: true,
            }
        },
        {
            name: "is_private",
            label: "Type",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value) ? 'Private' : 'Public'
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (parentId, start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/customers/' + customerId + '/folders/' + parentId + '/sub-folders'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(folder => {
                const editUrl = '/dashboard/customers/' + customerId + '/folders/' + folder.id
                const viewUrl = '/dashboard/customers/' + customerId + '/folders/' + folder.id + '/open'
                const docsUrl = '/dashboard/customers/' + customerId + '/folders/' + folder.id + '/documents'
                const edit = (folder.is_editable) ? (
                    <>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={editUrl}>
                                <Tooltip title="Edit Folder">
                                    <IconButton>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Tooltip title="Delete Folder">
                                <IconButton onClick={() => handleClickOpen(folder.id)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </>
                ) : ('')
                folder.name = (
                    <IconButton size="small" onClick={() => {
                        changeFolder(viewUrl, folder.id)
                    }}>
                        <FolderOpenIcon />&nbsp;{folder.name}
                    </IconButton>
                )
                folder.folders = (
                    <IconButton size="small" onClick={() => {
                        changeFolder(viewUrl, folder.id)
                    }}>
                        {folder.folders}
                    </IconButton>
                )
                folder.documents = (
                    <IconButton size="small" onClick={() => props.history.push(docsUrl)}>
                        {folder.documents}
                    </IconButton>
                )
                folder.action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={docsUrl}>
                                <Tooltip title="View Documents">
                                    <IconButton>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </ButtonGroup>
                        {edit}
                    </div>
                )
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getUser = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const customer = response.data
            const name = customer.first_name + ' ' + customer.last_name + '\'s Folders'
            setName(name)
            // getList()
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    // const getFolder = (parentId) => {
    //     setLoading(true)
    //     const headerConfig = {
    //         headers: {
    //             'accept': 'application/json',
    //             'Content-Type': 'application/json',
    //             token
    //         }
    //     }
    //     const url = config.url + '/customers/' + customerId + '/folders/' + parentId
    //     axios.get(url, headerConfig).then(response => {
    //         setFolderName(response.data.name)
    //     }).catch(error => {
    //         if (error.response) {
    //             if (error.response.status === 401) {
    //                 setAlertType('error')
    //                 setAlertMessage(error.response.data.detail)
    //                 setShowAlert(true)
    //             }
    //             console.log(error.response)
    //         } else if (error.request) {
    //             console.log(error.request)
    //         } else {
    //             console.log(error)
    //         }
    //         setLoading(false)
    //     })
    // }

    const getTree = (folderId = parentId) => {
        // setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/folders/' + folderId + '/tree'
        axios.get(url, headerConfig).then(response => {
            setTree(response.data)
            if (response.data.length > 1) {
                setCurrnetDir(response.data[response.data.length - 1].name);
            } else {
                setCurrnetDir(response.data[0].name);
            }
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            // setLoading(false)
        })
    }

    useEffect(() => {
        getUser()
        getList(parentId)
        // getFolder(parentId)
        getTree()
        // eslint-disable-next-line
    }, [])

    const changeFolder = (url, folderId) => {
        props.history.push(url)
        setParentId(folderId)
        // getFolder(folderId)
        getList(folderId)
        getTree(folderId)
    }


    const changePage = (page) => {
        const start = limit * (page)
        getList(parentId, start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(parentId, start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(parentId, start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(parentId, start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteUser = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/folders/' + folderId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Folder Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList(parentId)
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (folderId) => {
        setOpen(true)
        setFolderId(folderId)
    }

    const handleClose = () => {
        setOpen(false)
        setFolderId()
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>{currnetDir}</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/customers">
                                Clients
                            </MLink>
                            <MLink color="inherit" href={'/#/dashboard/customers/' + customerId + '/folders'}>
                                {name}
                            </MLink>
                            {tree.map((folder, index) => {
                                var item
                                const viewUrl = '/dashboard/customers/' + customerId + '/folders/' + folder.id + '/open'
                                if ((index + 1) !== tree.length) {
                                    item = (
                                        <MLink key={index} color="inherit" className="cursor-pointer" onClick={() => {
                                            changeFolder(viewUrl, folder.id)
                                        }}>
                                            {folder.name}
                                        </MLink>
                                    )
                                }
                                return item
                            })}
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="open/add"><Button variant="contained" color="primary" className="ml-2"><AddIcon />Add Folder</Button></Link>
                        <Link to="documents/add"><Button variant="contained" color="primary" className="ml-2"><AddIcon />Add Document</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Folder"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Folder ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteUser()} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="entry-div">
                {progress}
                <Table
                    title={'Folders'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                {progress}
            </div>
        </div>
    )
}

export default OpenFolder
