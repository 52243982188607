import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'

import Table from '../../../components/Table'
import axios from 'axios'
import config from '../../../config'

import RestoreFromTrashIcon from '@material-ui/icons/RestoreFromTrash'

import MuiAlert from '@material-ui/lab/Alert'
import { dateFormate } from '../../../functions'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const statusStyle = (slug) => {
    if (slug === 'cold') {
        return <Chip label="COLD" className="chip-cold" />
    } else if (slug === 'warm') {
        return <Chip label="WARM" className="chip-warm" />
    } else if (slug === 'hot') {
        return <Chip label="HOT" className="chip-hot" />
    } else if (slug === 'received') {
        return <Chip label="ORDER RECEIVED" className="chip-received" />
    } else if (slug === 'loss') {
        return <Chip label="ORDER LOSS" className="chip-loss" />
    } else {
        return ''
    }
}

const ListDeletedLeads = () => {
    const token = localStorage.getItem('bsToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [textSearch, setTextSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "lead_no",
            label: "Lead No",
            options: {
                sort: true,
            }
        },
        {
            name: "date",
            label: "Lead Date",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value === '') ? '' : dateFormate(value)
                }
            }
        },
        {
            name: "state",
            label: "State",
            options: {
                sort: false,
            }
        },
        {
            name: "city",
            label: "City",
            options: {
                sort: false,
            }
        },
        {
            name: "creator",
            label: "Gen. By",
            options: {
                sort: false,
            }
        },
        {
            name: "assignee",
            label: "Assigned To",
            options: {
                sort: false,
            }
        },
        {
            name: "company",
            label: "Company",
            options: {
                sort: true,
            }
        },
        {
            name: "person_name",
            label: "Person Name",
            options: {
                sort: true,
            }
        },
        {
            name: "person_number",
            label: "Mobile No",
            options: {
                sort: true,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: true,
                customBodyRender: statusStyle
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        },
    ]

    const getList = (type = 'all', start = 0, limit = 10, sortBy = 'all', order = 'all', textSearch = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            textSearch,
            type,
            deleted: true
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/leads'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                item.action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="Restore Lead">
                                <IconButton onClick={() => handleClickOpen(item.id)}>
                                    <RestoreFromTrashIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                )
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const restore = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + itemId + '/restore'
        axios.put(url, {}, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Restored.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const changePage = (page) => {
        const start = limit * (page)
        getList('all', start, limit, sortBy, order, textSearch)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList('all', start, limit, sortBy, order, textSearch)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList('all', start, limit, sortBy, order, textSearch)
        setLimit(limit)
    }

    const onSearch = (textSearch) => {
        getList('all', start, limit, sortBy, order, textSearch)
        setTextSearch(textSearch)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'textSearch':
                const textSearch = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(textSearch)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClose = () => {
        setOpen(false)
        setItemId(null)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Deleted Leads</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/crm/leads">
                        Leads
                    </MLink>
                </Breadcrumbs>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Restore Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to restore the Lead ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => restore()} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <div className="entry-div">
                {progress}
                <Table
                    title={'List Leads'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                {progress}
            </div>
        </div>
    )
}

export default ListDeletedLeads
