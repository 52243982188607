import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import ListNewsAttachments from './ListNewsAttachments'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateNews = (props) => {
    const newsId = props.match.params.newsId
    const token = localStorage.getItem('bsToken')
    const [categories, setCategories] = useState([])
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    // const [image, setImage] = useState('')
    const [category, setCategory] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getCategories = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/news/categories/all'
        axios.get(url, headerConfig).then(response => {
            setCategories(response.data)
            getNews()
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getNews = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/news/' + newsId
        axios.get(url, headerConfig).then(response => {
            const news = response.data
            setCategory(news.category)
            setTitle(news.title)
            setContent(news.content)
            // setImage(news.image)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line
    }, [])

    const handleTitleChange = (e) => {
        var value = e.target.value
        // value = ltrim(value)
        // value = rtrim(value)
        setTitle(value)
    }

    const handleContentChange = (e) => {
        var value = e.target.value
        setContent(value)
    }

    const handleCategoryChange = (e, category) => {
        setCategory(category)
    }

    // const handleImageChange = (e) => {
    //     var value = e.target.value
    //     value = ltrim(value)
    //     value = rtrim(value)
    //     setImage(value)
    // }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            title,
            content,
            // image,
            category_id: category.id
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/news/' + newsId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('News updated')
            setShowAlert(true)
            setLoading(false)
            // props.history.push('/dashboard/settings/users')
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Update News</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/news">
                        News
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Update News</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="rows"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    value={title}
                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                    onChange={handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={categories}
                                    autoHighlight
                                    value={category}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Category" required />}
                                    onChange={handleCategoryChange}
                                />
                            </Grid>

                            {/* <Grid item xs={9}>
                                <TextField
                                    required
                                    fullWidth
                                    id="image"
                                    label="Image"
                                    name="image"
                                    value={image}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleImageChange}
                                />
                            </Grid> */}
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="content"
                                    label="Content"
                                    name="content"
                                    multiline
                                    rows={15}
                                    variant="outlined"
                                    value={content}
                                    fullWidth
                                    inputProps={{ minLength: 3, maxLength: 50000 }}
                                    onChange={handleContentChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/news">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
            <ListNewsAttachments newsId={newsId} />
        </div>
    )
}

export default UpdateNews
