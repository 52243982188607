import React, { useEffect, useState } from 'react'
import {
    Badge,
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    Link as MLink,
    Tab,
    Tabs,
    TextField,
    Typography
} from '@material-ui/core';
import axios from 'axios'
import config from '../../../config'
import Autocomplete from '@material-ui/lab/Autocomplete'

import CheckBoxIcon from '@material-ui/icons/CheckBox'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { dateFormate } from '../../../functions'

import Followups from './view/Followups'
import Meetings from './view/Meetings'
import Quotations from './view/Quotations'

import { Link } from 'react-router-dom'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const statuses = [
    {
        name: 'Cold',
        slug: 'cold'
    },
    {
        name: 'Warm',
        slug: 'warm'
    },
    {
        name: 'Hot',
        slug: 'hot'
    },
    {
        name: 'Order Received',
        slug: 'received'
    },
    {
        name: 'Order Loss',
        slug: 'loss'
    }
]

const ViewLead = (props) => {
    const leadId = props.match.params.leadId
    const token = localStorage.getItem('bsToken')
    const [lead, setLead] = useState({
        company: {},
        source: {},
        service: {},
        country: {},
        state: {},
        city: {},
        creator: {},
        assignee: {},
        persons: []
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [content, setContent] = useState(null)
    const [countFollowups, setCountFollowups] = useState(0)
    const [countMeetings, setCountMeetings] = useState(0)
    const [countQuotations, setCountQuotations] = useState(0)

    const [value, setValue] = useState(0)
    const [openDialog, setOpenDialog] = useState(false)
    const [selectedStatus, setSelectedStatus] = useState(null)
    const [openDelete, setOpenDelete] = useState(false)
    const [reason, setReason] = useState('')

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setLead(item)
            setReason(item.loss_reason)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getCounts = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/counts'
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setCountFollowups(item.followups)
            setCountMeetings(item.meetings)
            setCountQuotations(item.quotations)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getData()
        getCounts()
        setContent(<Followups leadId={leadId} />)
        // eslint-disable-next-line
    }, [])

    const statusStyle = (slug) => {
        if (slug === 'cold') {
            return <Chip size="small" label="COLD" className="chip-cold" />
        } else if (slug === 'warm') {
            return <Chip size="small" label="WARM" className="chip-warm" />
        } else if (slug === 'hot') {
            return <Chip size="small" label="HOT" className="chip-hot" />
        } else if (slug === 'received') {
            return <Chip size="small" label="ORDER RECEIVED" className="chip-received" />
        } else if (slug === 'loss') {
            return <Chip size="small" label="ORDER LOSS" className="chip-loss" />
        } else {
            return ''
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        props.history.push('/dashboard/crm/leads')
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleChange = (event, newValue) => {
        if (newValue === 0) {
            setContent(<Followups leadId={leadId} />)
        } else if (newValue === 1) {
            setContent(<Meetings leadId={leadId} />)
        } else if (newValue === 2) {
            setContent(<Quotations leadId={leadId} />)
        }
        setValue(newValue)
    }

    const updateStatus = () => {
        setLoading(true)
        const data = {
            status: selectedStatus.slug,
            reason
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId + '/status'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated')
            setShowAlert(true)
            setLoading(false)
            getData()
            handleClose()
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const deleteItem = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }

        const url = config.url + '/leads/' + leadId
        axios.delete(url, headerConfig).then(response => {
            setLoading(false)
            setOpenDelete(false)
            props.history.push('/dashboard/crm/leads')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleClickOpen = (status) => {
        setOpenDialog(true)
        let _status = null
        if (status === 'cold') {
            _status = {
                name: 'Cold',
                slug: status
            }
        } else if (status === 'warm') {
            _status = {
                name: 'Warm',
                slug: status
            }
        } else if (status === 'hot') {
            _status = {
                name: 'Hot',
                slug: status
            }
        } else if (status === 'received') {
            _status = {
                name: 'Order Received',
                slug: status
            }
        } else if (status === 'loss') {
            _status = {
                name: 'Order Loss',
                slug: status
            }
        }
        setSelectedStatus(_status)
    }

    const handleReasonChange = (e) => {
        const value = e.target.value
        setReason(value)
    }

    const handleSelectedStatusChange = (event, value) => {
        setSelectedStatus(value)
    }

    const handleClickOpenDelete = () => {
        setOpenDelete(true)
    }

    const handleClose = () => {
        setOpenDialog(false)
        setOpenDelete(false)
        setSelectedStatus(null)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Lead</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/crm/leads">
                                Leads
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Button variant="contained" color="primary" onClick={() => handleClickOpen(lead.status)}><CheckBoxIcon />&nbsp;Change Status</Button>&nbsp;
                        <Link to={leadId + '/edit'}><Button variant="contained" color="primary" ><EditIcon />&nbsp;Edit</Button></Link>&nbsp;
                        <Button variant="contained" color="secondary" onClick={handleClickOpenDelete}><DeleteIcon />&nbsp;Delete</Button>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                fullWidth
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={(e) => {
                    e.preventDefault()
                    updateStatus()
                }}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Update Lead Status</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Autocomplete
                                id="status"
                                options={statuses}
                                getOptionLabel={(option) => option.name}
                                value={selectedStatus}
                                onChange={handleSelectedStatusChange}
                                renderInput={(params) => (
                                    <TextField
                                        required
                                        {...params}
                                        name="status"
                                        variant="outlined"
                                        label="Lead Status"
                                    />
                                )}
                            />
                            {(selectedStatus !== null && (selectedStatus.slug === 'loss' || selectedStatus.slug === 'received')) ? (
                                <>
                                    <br />
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        id="reason"
                                        label="Note"
                                        name="note"
                                        value={reason}
                                        inputProps={{ maxLength: 255, rows: 5 }}
                                        onChange={handleReasonChange}
                                    />
                                </>
                            ) : ''}

                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Lead</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Lead ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} variant="outlined" disabled={loading} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <form onSubmit={onSubmit}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                            >
                                <Grid item xs={12} lg={12} >
                                    <div className="before">
                                        <h2 className="form-heading">Lead Details</h2>
                                    </div>
                                    <hr />
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderRight: "unset" }}>
                                    <b>Lead No</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderRight: "unset" }}>
                                    {lead.lead_no}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderRight: "unset" }}>
                                    <b>Date</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border">
                                    {dateFormate(lead.date)}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Company/Owner Name</b>
                                </Grid>
                                <Grid item xs={12} lg={10} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.company.company}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Owner Mobile</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.owner_mobile}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Owner Email</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.owner_email}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Lead Source</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.source.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Service</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.service.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Branch</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.branch}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Website</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.website}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Address</b>
                                </Grid>
                                <Grid item xs={12} lg={10} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.address}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Country</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.country.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>State</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.state.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>City</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.city.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Approx Amount</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    Rs. {lead.amount}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Generated By</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {lead.creator.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Assigned To</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                    {lead.assignee.name}
                                </Grid>
                                <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    <b>Status</b>
                                </Grid>
                                <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                    {statusStyle(lead.status)}
                                </Grid>
                                {(lead.status === 'loss' || lead.status === 'received') ? (
                                    <>
                                        <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                            <b>Note</b>
                                        </Grid>
                                        <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                            {lead.loss_reason}
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset", borderBottom: "unset" }}>

                                    </Grid>
                                )}
                                <Grid item xs={12} lg={12}>
                                    <div className="before mt-3">
                                        <h2 className="form-heading">Contact Details</h2>
                                    </div>
                                    <hr />
                                </Grid>
                                {lead.persons.map((person, i) => {
                                    let style1
                                    let style2
                                    if (i === 0) {
                                        style1 = {
                                            borderRight: "unset"
                                        }
                                        style2 = {}
                                    } else {
                                        style1 = {
                                            borderTop: "unset",
                                            borderRight: "unset"
                                        }
                                        style2 = { borderTop: "unset" }
                                    }
                                    const item = (
                                        <>
                                            <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                                <b>Name</b>
                                            </Grid>
                                            <Grid item xs={12} lg={3} className="grid-border" style={style1}>
                                                {person.name}
                                            </Grid>
                                            <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                                <b>Mobile</b>
                                            </Grid>
                                            <Grid item xs={12} lg={3} className="grid-border" style={style1}>
                                                {person.number}
                                            </Grid>
                                            <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                                <b>Email</b>
                                            </Grid>
                                            <Grid item xs={12} lg={3} className="grid-border" style={style2}>
                                                {person.email}
                                            </Grid>
                                        </>
                                    )
                                    return item
                                })}
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
            <br />
            <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                centered
            >
                <Tab label={(
                    <div>
                        Followups
                        <Badge badgeContent={countFollowups} className="ml-3" color="secondary"></Badge>
                    </div>
                )} />
                <Tab label={(
                    <div>
                        Meetings
                        <Badge badgeContent={countMeetings} className="ml-3" color="secondary"></Badge>
                    </div>
                )} />
                <Tab label={(
                    <div>
                        Quotations
                        <Badge badgeContent={countQuotations} className="ml-3" color="secondary"></Badge>
                    </div>
                )} />
            </Tabs>
            {content}
        </div>
    )
}

export default ViewLead
