import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../components/Table'
import axios from 'axios'
import config from '../config'
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListNotifications = (props) => {
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const token = localStorage.getItem('bsToken')

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta.rowIndex
                    const isRead = tableData[index].is_read
                    const count = start + index + 1
                    let item
                    if (isRead) {
                        item = (<span style={{ color: 'gray' }}>{count}</span>)
                    } else {
                        item = (<span><b>{count}</b></span>)
                    }
                    return item
                }
            }
        },
        {
            name: "message",
            label: "Notification",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta.rowIndex
                    const isRead = tableData[index].is_read
                    let item
                    if (isRead) {
                        item = (<span style={{ color: 'gray' }}>{value}</span>)
                    } else {
                        item = (<span><b>{value}</b></span>)
                    }
                    return item
                }
            }
        },
        {
            name: "created_at",
            label: "Time",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const index = tableMeta.rowIndex
                    const isRead = tableData[index].is_read
                    let item
                    if (isRead) {
                        item = (<span style={{ color: 'gray' }}>{value}</span>)
                    } else {
                        item = (<span><b>{value}</b></span>)
                    }
                    return item
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/notifications'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={item.url}>
                                <Tooltip title="View">
                                    <IconButton onClick={() => {
                                        if (!item.is_read) {
                                            isRead(item.id)
                                        }
                                    }}>
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </ButtonGroup>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const isRead = (itemId) => {
        const params = {
            recent: true
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/notifications/' + itemId
        axios.put(url, {}, headerConfig).then(response => {
            const _count = count - 1
            setCount(_count)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Notifications</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                <Table
                    title={'List Notifications'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div>
    )
}

export default ListNotifications
