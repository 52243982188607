import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'


function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddBanner = (props) => {
    const token = localStorage.getItem('bsToken')
    const [title, setTitleName] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [fileData, setFileData] = useState(null)
    const [file, setFile] = useState(null)

    const handleFileChange = (event) => {
        let files = event.target.files
        let file = files[0]
        let fileInfo = {
            name: file.name,
            type: file.type,
            size: (file.size / 1000000).toFixed(2) + ' MB',
            file: file,
        }
        const info = fileInfo.size + ' - ' + file.name
        setFileData(info)
        setFile(fileInfo)
    }

    const handleTitleChange = (e) => {
        var value = e.target.value
        setTitleName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (file === null) {
            setAlertType('error')
            setAlertMessage('Please select a file.')
            setShowAlert(true)
            setLoading(false)
        } else {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            var formData = new FormData();
            formData.append("file", file.file)
            formData.append("title", title)
            const url = config.url + '/banners'
            axios.post(url, formData, headerConfig).then(response => {
                setLoading(false)
                setAlertType('success')
                setAlertMessage('Document added.')
                setShowAlert(true)
                props.history.push('/dashboard/settings/banners')
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    } else if (error.response.status === 403) {
                        message = error.response.data.detail
                    } else if (error.response.status === 422) {
                        message = error.response.data.detail
                    } else {
                        console.log(error.response)
                    }
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add Banner</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/settings/banners">
                        Banners
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Add Banner</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="dName"
                                    label="Banner Title"
                                    name="fName"
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                Banner size should be: 600x200 px<br />
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Select File
                                    <input
                                        type="file"
                                        accept="image/png,image/jpeg,application/pdf"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button> &nbsp;&nbsp;
                                {fileData}
                            </Grid>
                            <Grid item xs={12}>
                                <hr />
                                <Link to={'/dashboard/settings/banners'}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Upload
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddBanner
