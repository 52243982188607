import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddTicket = (props) => {
    const token = localStorage.getItem('bsToken')
    const [title, setTitle] = useState(null)
    const [customers, setCustomers] = useState([])
    const [customer, setCustomer] = useState(null)
    const [ticketTypes, setTicketTypes] = useState([])
    const [ticketType, setTicketType] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getCustomers = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/invoices/customers'
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getTicketTypes = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/ticket-types/all'
        axios.get(url, headerConfig).then(response => {
            setTicketTypes(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomers()
        getTicketTypes()
        // eslint-disable-next-line
    }, [])

    const handleTitleChange = (e) => {
        var value = e.target.value
        setTitle(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        // setLoading(true)
        var _title = title
        _title = ltrim(_title)
        _title = rtrim(_title)
        const data = {
            customer_id: customer.id,
            type_id: ticketType.id,
            title: _title

        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/tickets'
        axios.post(url, data, headerConfig).then(response => {
            setLoading(false)
            setAlertType('success')
            setAlertMessage('Ticket added.')
            setShowAlert(true)
            props.history.push('/dashboard/tickets')
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleCustomerChange = (e, value) => {
        setCustomer(value)
    }

    const handleTicketTypeChange = (e, value) => {
        setTicketType(value)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add Ticket</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/tickets">
                        Tickets
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Add Ticket</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                >
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={customers}
                                            autoHighlight
                                            required
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Customer" required />}
                                            onChange={handleCustomerChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={ticketTypes}
                                            autoHighlight
                                            required
                                            getOptionLabel={(option) => option.name}
                                            renderInput={(params) => <TextField {...params} label="Ticket Type" required />}
                                            onChange={handleTicketTypeChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            label="Title"
                                            id="title"
                                            onChange={handleTitleChange}
                                            inputProps={{ minLength: 5, maxLength: 150 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Link to="/dashboard/tickets">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                            >
                                                Cancel
                                            </Button>
                                        </Link>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={loading}
                                        >
                                            Add
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddTicket
