import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    ButtonGroup,
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'

import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import ImageBase from '../../../components/ImageBase'
import { dateToString, timeToString } from '../../../functions'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const leadStatues = [
    {
        name: 'Cold',
        slug: 'cold'
    },
    {
        name: 'Hot',
        slug: 'hot'
    },
    {
        name: 'Warm',
        slug: 'warm'
    }
]

const branches = [
    'Bhuj',
    'Mankuva',
    'Rakjot'
]

const AddLead = (props) => {
    const token = localStorage.getItem('bsToken')

    const [leadSources, setLeadSources] = useState([])
    const [services, setServices] = useState([])
    const [users, setUsers] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [company, setCompany] = useState('')
    // const [ownerName, setOwnerName] = useState('')
    const [ownerMobile, setOwnerMobile] = useState('')
    const [ownerEmail, setOwnerEmail] = useState('')
    // const [personName, setPersonName] = useState('')
    // const [personNumber, setPersonNumber] = useState('')
    // const [personEmail, setPersonEmail] = useState('')
    const [address, setAddress] = useState('')
    const [website, setWebsite] = useState('')
    const [country, setCountry] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    // const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [date, setDate] = useState(moment())
    const [source, setSource] = useState(null)
    const [service, setService] = useState(null)
    const [assignee, setAssignee] = useState(null)
    const [status, setStatus] = useState(leadStatues[0])
    const [branch, setBranch] = useState(branches[2])

    const [nextFollowupDate, setNextFollowupDate] = useState(moment())
    const [nextFollowupTime, setNextFollowupTime] = useState(moment())
    const [followupRemarks, setFollowupRemarks] = useState('')

    const [isMeeting, setIsMeeting] = useState('no')
    const [meetingDate, setMeetingDate] = useState(moment())
    const [meetingFromTime, setMeetingFromTime] = useState(moment())
    const [meetingToTime, setMeetingToTime] = useState(moment().add(1, 'hours'))
    const [meetingRemarks, setMeetingRemarks] = useState('')

    const [isQuotation, setIsQuotation] = useState('no')
    const [quotationDate, setQuotationDate] = useState(moment())
    const [quotationAmount, setQuotationAmount] = useState('')
    const [quotationRemarks, setQuotationRemarks] = useState('')

    const [meetingDisabled, setMeetingDisabled] = useState(true)
    const [quotationDisabled, setQuotationDisabled] = useState(true)

    const [companies, setCompanies] = useState([])

    const [inputList, setInputList] = useState([])
    const [fileData, setFileData] = useState(null)
    const [file, setFile] = useState(null)

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const defaultCountry = () => {
        const country = {
            id: '65295783-b449-4fda-9253-2e72c535d3f3',
            name: 'India'
        }
        const state = {
            id: 'f0df14a6-bb9c-47e0-8ee6-c5939b6cc7d9',
            name: 'Gujarat'
        }
        const city = {
            id: '64b9caa4-9a40-4053-9823-9f85565ac78d',
            name: 'Rajkot'
        }
        setCountry(country)
        getStates(country.id)
        setState(state)
        getCities(state.id)
        setCity(city)
    }

    const getSources = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/lead-sources/all'
        axios.get(url, headerConfig).then(response => {
            setLeadSources(response.data)
            setSource(response.data[0])
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getServices = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/services/all'
        axios.get(url, headerConfig).then(response => {
            setServices(response.data)
            setService(response.data[0])
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getUsers = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/all'
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
            setAssignee(response.data[0])
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getCountries = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/countries'
        axios.get(url, headerConfig).then(response => {
            setCountries(response.data)
            defaultCountry()
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getCompanies = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/crm/customers/all'
        axios.get(url, headerConfig).then(response => {
            setCompanies(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getCompanies()
        getSources()
        getServices()
        getUsers()
        getCountries()
        // eslint-disable-next-line
    }, [])

    const getFile = (file) => {
        let fileInfo = {
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
        }
        const info = fileInfo.size + ' - ' + file.name
        setFileData(info)
        setFile(file.base64)
    }

    const getStates = (country_id) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/states/' + country_id
        axios.get(url, headerConfig).then(response => {
            setStates(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getCities = (state_id) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/cities/' + state_id
        axios.get(url, headerConfig).then(response => {
            setCities(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const meeting = {}
        const quotation = {}
        let is_meeting = false
        let is_quotation = false


        const data = {
            company: company,
            // owner_name: ownerName,
            owner_mobile: ownerMobile,
            owner_email: ownerEmail,
            // person_name: personName,
            // person_number: personNumber,
            // person_email: personEmail,
            address,
            website,
            persons: inputList,
            city_id: city.id,
            // name: name,
            amount: amount,
            date: dateToString(date),
            source_id: source.id,
            service_id: service.id,
            assignee_id: assignee.id,
            status: status.slug,
            branch,
            followup: {
                next_date: dateToString(nextFollowupDate),
                next_time: timeToString(nextFollowupTime),
                remarks: followupRemarks
            },
            is_meeting,
            is_quotation
        }

        if (isMeeting === 'yes') {
            meeting.date = dateToString(meetingDate)
            meeting.from_time = timeToString(meetingFromTime)
            meeting.to_time = timeToString(meetingToTime)
            meeting.remarks = meetingRemarks
            data.is_meeting = true
            data.meeting = meeting
        }
        if (isQuotation === 'yes') {
            quotation.date = dateToString(quotationDate)
            quotation.amount = quotationAmount
            quotation.remarks = quotationRemarks
            quotation.file = (file === null) ? '' : file
            data.is_quotation = true
            data.quotation = quotation
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads'
        axios.post(url, data, headerConfig).then(response => {
            setLoading(false)
            props.history.push('/dashboard/crm/leads')
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleCompanyChange = (e, value) => {
        // setOwnerName(value.owner_name)
        setOwnerMobile(value.owner_mobile)
        setOwnerEmail(value.owner_email)
        // setPersonName(value.person_name)
        // setPersonNumber(value.person_number)
        // setPersonEmail(value.person_email)
        setAddress(value.address)
        setWebsite(value.website)
        setInputList(value.persons)
    }

    const handleCompanyInputChange = (e, value) => {
        setCompany(value)
    }

    // const handleOwnerNameChange = (e) => {
    //     const value = e.target.value
    //     setOwnerName(value)
    // }

    const handleOwnerMobileChange = (e) => {
        const value = e.target.value
        setOwnerMobile(value)
    }

    const handleOwnerEmailChange = (e) => {
        const value = e.target.value
        setOwnerEmail(value)
    }

    // const handlePersonNameChange = (e) => {
    //     const value = e.target.value
    //     setPersonName(value)
    // }

    // const handlePersonNumberChange = (e) => {
    //     const value = e.target.value
    //     setPersonNumber(value)
    // }

    // const handlePersonEmailChange = (e) => {
    //     const value = e.target.value
    //     setPersonEmail(value)
    // }

    const handleAddressChange = (e) => {
        const value = e.target.value
        setAddress(value)
    }

    const handleWebsiteChange = (e) => {
        const value = e.target.value
        setWebsite(value)
    }

    const handleCountryChange = (e, item) => {
        if (item !== null) {
            getStates(item.id)
        }
        setCountry(item)
        setState(null)
        setCity(null)
    }

    const handleStateChange = (e, item) => {
        setState(item)
        if (item !== null) {
            getCities(item.id)
        }
        setCity(null)
    }

    const handleCityChange = (e, item) => {
        setCity(item)
    }

    // const handleNameChange = (e) => {
    //     const value = e.target.value
    //     setName(value)
    // }

    const handleAmountChange = (e) => {
        const value = e.target.value
        setAmount(value)
    }

    const handleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setDate(date)
    }

    const handleSourceChange = (e, item) => {
        setSource(item)
    }

    const handleServiceChange = (e, item) => {
        setService(item)
    }

    const handleAssigneeChange = (e, item) => {
        setAssignee(item)
    }

    const handleStatusChange = (e, item) => {
        setStatus(item)
    }

    const handleBranchChange = (e, item) => {
        setBranch(item)
    }

    const handleNextFollowupDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupDate(date)
    }

    const handleNextFollowupTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setNextFollowupTime(date)
    }

    const handleFollowupRemarksChange = (e) => {
        const value = e.target.value
        setFollowupRemarks(value)
    }

    const handleIsMeetingChange = (e, newAlignment) => {
        setIsMeeting(newAlignment)
        if (newAlignment === 'no') {
            setMeetingDisabled(true)
        } else {
            setMeetingDisabled(false)
        }
    }

    const handleMeetingDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setMeetingDate(date)
    }

    const handleMeetingFromTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setMeetingFromTime(date)
    }

    const handleMeetingToTimeChange = (selectedDate) => {
        const date = moment(selectedDate)
        setMeetingToTime(date)
    }

    const handleMeetingRemarksChange = (e) => {
        const value = e.target.value
        setMeetingRemarks(value)
    }

    const handleIsQuotationChange = (e, newAlignment) => {
        setIsQuotation(newAlignment)
        if (newAlignment === 'no') {
            setQuotationDisabled(true)
        } else {
            setQuotationDisabled(false)
        }
    }

    const handleQuotationDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setQuotationDate(date)
    }

    const handleQuotationAmountChange = (e) => {
        const value = e.target.value
        setQuotationAmount(value)
    }

    const handleQuotationRemarksChange = (e) => {
        const value = e.target.value
        setQuotationRemarks(value)
    }

    const handleAlertClose = (e, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    }

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { id:'', name: '', email: '', number: '' }]);
    }

    const handleCopyClick = () => {
        setInputList([{ id:'', name: company, email: ownerEmail, number: ownerMobile }, ...inputList]);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add Lead</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/crm/leads">
                        Leads
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <div className="before">
                            <h2 className="form-heading">Lead Details</h2>
                        </div>
                        <hr /><br />
                        <form onSubmit={onSubmit}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                            >
                                {/* <Grid item xs={12} lg={8}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="name"
                                    label="Lead Name"
                                    name="name"
                                    inputProps={{ minLength: 3, maxLength: 250 }}
                                    onChange={handleNameChange}
                                />
                            </Grid> */}
                                <Grid item xs={12} lg={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            id="date-picker"
                                            label="Date"
                                            value={date}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={users}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={assignee}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Assign To" required />}
                                        onChange={handleAssigneeChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={leadSources}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={source}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Lead Source" required />}
                                        onChange={handleSourceChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={services}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={service}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Service" required />}
                                        onChange={handleServiceChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        variant="outlined"
                                        id="amount"
                                        label="Approx Amount"
                                        name="amount"
                                        inputProps={{ pattern: "[+-]?([0-9]*[.])?[0-9]+", title: "Numbers only 1.0", minLength: 1, maxLength: 10 }}
                                        onChange={handleAmountChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={leadStatues}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={status}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Lead Status" required />}
                                        onChange={handleStatusChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={branches}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        value={branch}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Branch" required />}
                                        onChange={handleBranchChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div className="before">
                                        <h2 className="form-heading">Company Information</h2>
                                    </div>
                                    <hr />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={companies}
                                        getOptionLabel={(option) => option.company}
                                        onChange={handleCompanyChange}
                                        onInputChange={handleCompanyInputChange}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Company/Person Name"
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="ownerName"
                                    label="Owner Name"
                                    name="ownerName"
                                    value={ownerName}
                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                    onChange={handleOwnerNameChange}
                                />
                            </Grid> */}
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="ownerMobile"
                                        label="Mobile"
                                        name="ownerMobile"
                                        value={ownerMobile}
                                        inputProps={{ minLength: 3, maxLength: 13 }}
                                        onChange={handleOwnerMobileChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="ownerEmail"
                                        label="Email"
                                        name="ownerEmail"
                                        value={ownerEmail}
                                        inputProps={{ minLength: 3, maxLength: 150 }}
                                        onChange={handleOwnerEmailChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        id="address"
                                        label="Address"
                                        name="address"
                                        value={address}
                                        inputProps={{ maxLength: 250, rows: 5 }}
                                        onChange={handleAddressChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="website"
                                        label="Website"
                                        name="website"
                                        value={website}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleWebsiteChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                    >
                                        <Grid item xs={12} lg={2}>
                                            <div className="before">
                                                <h2 className="form-heading">Company Person Details</h2>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <ButtonGroup size="small" aria-label="small outlined button group">
                                                <Tooltip title="Add">
                                                    <IconButton onClick={handleAddClick}>
                                                        <AddIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title="Same as Above">
                                                    <IconButton onClick={handleCopyClick}>
                                                        <FileCopyIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {inputList.map((item, i) => {
                                    return (
                                        <>
                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    id="person"
                                                    label="Person Name"
                                                    name="name"
                                                    value={item.name}
                                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    id="number"
                                                    label="Person Number"
                                                    name="number"
                                                    value={item.number}
                                                    inputProps={{ minLength: 3, maxLength: 13 }}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                >
                                                    <Grid item xs={11} lg={11}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="email"
                                                            label="Person Email"
                                                            name="email"
                                                            value={item.email}
                                                            inputProps={{ maxLength: 150 }}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} lg={1}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => handleRemoveClick(i)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    );
                                })}

                                <Grid item xs={12} lg={12}>
                                    <div className="before">
                                        <h2 className="form-heading">Location Information</h2>
                                    </div>
                                    <hr />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={country}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Country" required />}
                                        onChange={handleCountryChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={states}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={state}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="State" required />}
                                        onChange={handleStateChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={cities}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={city}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="City" required />}
                                        onChange={handleCityChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <div className="before">
                                        <h2 className="form-heading">Followup Details</h2>
                                    </div>
                                    <hr />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            id="date-picker"
                                            label="Next Followup Date"
                                            value={nextFollowupDate}
                                            onChange={handleNextFollowupDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardTimePicker
                                            required
                                            fullWidth
                                            inputVariant="outlined"
                                            id="time-picker"
                                            label="Next Followup Time"
                                            value={nextFollowupTime}
                                            onChange={handleNextFollowupTimeChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        id="name"
                                        label="Remarks"
                                        name="name"
                                        inputProps={{ maxLength: 250, rows: 5 }}
                                        onChange={handleFollowupRemarksChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                    >
                                        <Grid item xs={12} lg={12}>
                                            <div className="before">
                                                <h2 className="form-heading">Meeting Details</h2>
                                            </div>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            Personal Meeting Required ? &nbsp;&nbsp;
                                            <ToggleButtonGroup
                                                value={isMeeting}
                                                exclusive
                                                style={{ width: "100%" }}
                                                onChange={handleIsMeetingChange}
                                                aria-label="text isMeeting"
                                            >
                                                <ToggleButton value="yes" aria-label="yes" style={{ width: "50%" }}>
                                                    Yes
                                                </ToggleButton>
                                                <ToggleButton value="no" aria-label="no" style={{ width: "50%" }}>
                                                    No
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    required
                                                    fullWidth
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    id="date-picker"
                                                    label="Meeting Date"
                                                    value={meetingDate}
                                                    onChange={handleMeetingDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    disabled={meetingDisabled}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    required
                                                    fullWidth
                                                    inputVariant="outlined"
                                                    id="time-picker"
                                                    label="From Time"
                                                    value={meetingFromTime}
                                                    onChange={handleMeetingFromTimeChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    disabled={meetingDisabled}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    required
                                                    fullWidth
                                                    inputVariant="outlined"
                                                    id="time-picker"
                                                    label="To Time"
                                                    value={meetingToTime}
                                                    onChange={handleMeetingToTimeChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change time',
                                                    }}
                                                    disabled={meetingDisabled}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                                id="name"
                                                label="Meeting Remarks"
                                                name="name"
                                                inputProps={{ maxLength: 250, rows: 5 }}
                                                onChange={handleMeetingRemarksChange}
                                                disabled={meetingDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                    >
                                        <Grid item xs={12} lg={12}>
                                            <div className="before">
                                                <h2 className="form-heading">Quotation Details</h2>
                                            </div>
                                            <hr />
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            Quotation Required ? &nbsp;&nbsp;
                                            <ToggleButtonGroup
                                                value={isQuotation}
                                                exclusive
                                                style={{ width: "100%" }}
                                                onChange={handleIsQuotationChange}
                                                aria-label="text isMeeting"
                                            >
                                                <ToggleButton value="yes" aria-label="yes" style={{ width: "50%" }}>
                                                    Yes
                                                </ToggleButton>
                                                <ToggleButton value="no" aria-label="no" style={{ width: "50%" }}>
                                                    No
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardDatePicker
                                                    required
                                                    fullWidth
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    id="date-picker"
                                                    label="Quotation Date"
                                                    value={quotationDate}
                                                    onChange={handleQuotationDateChange}
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'change date',
                                                    }}
                                                    disabled={quotationDisabled}
                                                />
                                            </MuiPickersUtilsProvider>
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <TextField
                                                required
                                                fullWidth
                                                variant="outlined"
                                                id="name"
                                                label="Quotation Amount"
                                                name="name"
                                                inputProps={{ pattern: "[+-]?([0-9]*[.])?[0-9]+", title: "Numbers only 1.0", minLength: 1, maxLength: 10 }}
                                                onChange={handleQuotationAmountChange}
                                                disabled={quotationDisabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <ImageBase
                                                multiple={false}
                                                onDone={getFile}
                                                disabled={quotationDisabled}
                                            />
                                            {fileData}
                                        </Grid>
                                        <Grid item xs={12} lg={12}>
                                            <TextField
                                                fullWidth
                                                multiline
                                                variant="outlined"
                                                id="name"
                                                label="Quotation Remarks"
                                                name="name"
                                                inputProps={{ maxLength: 250, rows: 5 }}
                                                onChange={handleQuotationRemarksChange}
                                                disabled={quotationDisabled}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className="text-center">
                                    <hr />
                                    <Link to="/dashboard/crm/leads">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                        >
                                            Back
                                        </Button>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default AddLead
