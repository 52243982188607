import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'

import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'

import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { dateToString, stringToDate } from '../../../functions'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const branches = [
    'Bhuj',
    'Mankuva',
    'Rakjot'
]

const EditLead = (props) => {
    const token = localStorage.getItem('bsToken')
    const leadId = props.match.params.leadId

    const [leadSources, setLeadSources] = useState([])
    const [services, setServices] = useState([])
    const [users, setUsers] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])

    const [company, setCompany] = useState('')
    // const [ownerName, setOwnerName] = useState('')
    const [ownerMobile, setOwnerMobile] = useState('')
    const [ownerEmail, setOwnerEmail] = useState('')
    // const [personName, setPersonName] = useState('')
    // const [personNumber, setPersonNumber] = useState('')
    // const [personEmail, setPersonEmail] = useState('')
    const [address, setAddress] = useState('')
    const [website, setWebsite] = useState('')
    const [country, setCountry] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    // const [name, setName] = useState('')
    const [amount, setAmount] = useState('')
    const [date, setDate] = useState(null)
    const [source, setSource] = useState(null)
    const [service, setService] = useState(null)
    const [assignee, setAssignee] = useState(null)
    const [branch, setBranch] = useState(null)

    const [companies, setCompanies] = useState([])
    const [companyName, setCompanyName] = useState('Name')

    const [inputList, setInputList] = useState([])

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getSources = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/lead-sources/all'
        axios.get(url, headerConfig).then(response => {
            setLeadSources(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getServices = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/services/all'
        axios.get(url, headerConfig).then(response => {
            setServices(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getUsers = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/all'
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getCountries = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/countries'
        axios.get(url, headerConfig).then(response => {
            setCountries(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            // setName(item.name)
            setAmount(item.amount)
            setDate(stringToDate(item.date))
            setSource(item.source)
            setService(item.service)
            setCountry(item.country)
            setState(item.state)
            setCity(item.city)
            setCompany(item.company)
            // setPersonName(item.person_name)
            // setPersonNumber(item.person_number)
            setAssignee(item.assignee)
            // setOwnerName(item.owner_name)
            setOwnerMobile(item.owner_mobile)
            setOwnerEmail(item.owner_email)
            // setPersonEmail(item.person_email)
            setAddress(item.address)
            setWebsite(item.website)
            setBranch(item.branch)
            setCompanyName(item.company.company)
            setInputList(item.persons)
            defaultCountry(item.country, item.state)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getCompanies = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/crm/customers/all'
        axios.get(url, headerConfig).then(response => {
            setCompanies(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getCompanies()
        getSources()
        getServices()
        getUsers()
        getCountries()
        getData()
        // eslint-disable-next-line
    }, [])

    const defaultCountry = (country, state) => {
        setCountry(country)
        getStates(country.id)
        setState(state)
        getCities(state.id)
    }

    const getStates = (country_id) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/states/' + country_id
        axios.get(url, headerConfig).then(response => {
            setStates(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getCities = (state_id) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/cities/' + state_id
        axios.get(url, headerConfig).then(response => {
            setCities(response.data)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleCompanyChange = (e, value) => {
        // setOwnerName(value.owner_name)
        setOwnerMobile(value.owner_mobile)
        setOwnerEmail(value.owner_email)
        // setPersonName(value.person_name)
        // setPersonNumber(value.person_number)
        // setPersonEmail(value.person_email)
        setAddress(value.address)
        setWebsite(value.website)
        setInputList(value.persons)
        setCompany(value)
    }

    const handleCompanyInputChange = (e, value) => {
        setCompanyName(value)
    }

    const handleAmountChange = (e) => {
        var value = e.target.value
        setAmount(value)
    }

    const handleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setDate(date)
    }

    const handleSourceChange = (e, value) => {
        setSource(value)
    }

    const handleServiceChange = (e, value) => {
        setService(value)
    }

    // const handlePersonNameChange = (e) => {
    //     var value = e.target.value
    //     setPersonName(value)
    // }

    // const handlePersonNumberChange = (e) => {
    //     var value = e.target.value
    //     setPersonNumber(value)
    // }

    const handleAssigneeChange = (e, value) => {
        setAssignee(value)
    }

    // const handleOwnerNameChange = (e) => {
    //     var value = e.target.value
    //     setOwnerName(value)
    // }

    const handleOwnerMobileChange = (e) => {
        var value = e.target.value
        setOwnerMobile(value)
    }

    const handleOwnerEmailChange = (e) => {
        var value = e.target.value
        setOwnerEmail(value)
    }

    // const handlePersonEmailChange = (e) => {
    //     var value = e.target.value
    //     setPersonEmail(value)
    // }

    const handleAddressChange = (e) => {
        var value = e.target.value
        setAddress(value)
    }

    const handleWebsiteChange = (e) => {
        const value = e.target.value
        setWebsite(value)
    }

    const handleCountryChange = (e, item) => {
        if (item !== null) {
            getStates(item.id)
        }
        setCountry(item)
        setState(null)
        setCity(null)
    }

    const handleStateChange = (e, item) => {
        setState(item)
        if (item !== null) {
            getCities(item.id)
        }
        setCity(null)
    }

    const handleCityChange = (e, item) => {
        setCity(item)
    }

    // const handleNameChange = (e) => {
    //     const value = e.target.value
    //     setName(value)
    // }

    const handleBranchChange = (e, item) => {
        setBranch(item)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            company: companyName,
            // owner_name: ownerName,
            owner_mobile: ownerMobile,
            owner_email: ownerEmail,
            // person_name: personName,
            // person_number: personNumber,
            // person_email: personEmail,
            address,
            website,
            persons: inputList,
            city_id: city.id,
            // name: name,
            amount: amount,
            date: dateToString(date),
            source_id: source.id,
            service_id: service.id,
            assignee_id: assignee.id,
            branch,
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/leads/' + leadId
        axios.put(url, data, headerConfig).then(response => {
            setLoading(false)
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { id:'', name: '', email: '', number: '' }]);
    };

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Edit Lead</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/crm/leads">
                        Leads
                    </MLink>
                    <MLink color="inherit" href={"/#/dashboard/crm/leads/" + leadId}>
                        View Lead
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                {progress}
                <Card>
                    <CardContent>
                        <form onSubmit={onSubmit}>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                            >
                                <Grid item xs={12} lg={12}>
                                    <div className="before mt-3">
                                        <h2 className="form-heading">Lead Details</h2>
                                    </div>
                                </Grid>
                                {/* <Grid item xs={12} lg={8}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="name"
                                    label="Lead Name"
                                    name="name"
                                    value={name}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleNameChange}
                                />
                            </Grid> */}
                                <Grid item xs={12} lg={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            required
                                            fullWidth
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            id="date-picker"
                                            label="Date"
                                            value={date}
                                            onChange={handleDateChange}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={users}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={assignee}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Assign To" required />}
                                        onChange={handleAssigneeChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={leadSources}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={source}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Lead Source" required />}
                                        onChange={handleSourceChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={services}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={service}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Service" required />}
                                        onChange={handleServiceChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        required
                                        fullWidth
                                        variant="outlined"
                                        id="amount"
                                        label="Approx Amount"
                                        name="amount"
                                        value={amount}
                                        inputProps={{ pattern: "[+-]?([0-9]*[.])?[0-9]+", title: "Numbers only 1.0", maxLength: 10 }}
                                        onChange={handleAmountChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={branches}
                                        autoHighlight
                                        getOptionLabel={(option) => option}
                                        value={branch}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Branch" required />}
                                        onChange={handleBranchChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <div className="before mt-3">
                                        <h2 className="form-heading">Company Information</h2>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        options={companies}
                                        getOptionLabel={(option) => option.company}
                                        onChange={handleCompanyChange}
                                        onInputChange={handleCompanyInputChange}
                                        value={company}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                required
                                                label="Company/Owner Name"
                                                variant="outlined"
                                                value={companyName}
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="company"
                                    label="Owner Name"
                                    name="company"
                                    value={ownerName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleOwnerNameChange}
                                />
                            </Grid> */}
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="company"
                                        label="Mobile"
                                        name="company"
                                        value={ownerMobile}
                                        inputProps={{ minLength: 10, maxLength: 13 }}
                                        onChange={handleOwnerMobileChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="company"
                                        label="Email"
                                        name="company"
                                        value={ownerEmail}
                                        inputProps={{ minLength: 3, maxLength: 50 }}
                                        onChange={handleOwnerEmailChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        multiline
                                        variant="outlined"
                                        id="number"
                                        label="Address"
                                        name="number"
                                        value={address}
                                        inputProps={{ maxLength: 250, rows: 5 }}
                                        onChange={handleAddressChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={12}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="website"
                                        label="Website"
                                        name="website"
                                        value={website}
                                        inputProps={{ maxLength: 100 }}
                                        onChange={handleWebsiteChange}
                                    />
                                </Grid>

                                <Grid item xs={12} lg={12}>
                                    <Grid
                                        container
                                        spacing={3}
                                        direction="row"
                                    >
                                        <Grid item xs={12} lg={3}>
                                            <div className="before pt-2">
                                                <h2 className="form-heading">Company Person Details</h2>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} lg={4}>
                                            <Tooltip title="Add">
                                                <IconButton onClick={handleAddClick}>
                                                    <AddIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {inputList.map((item, i) => {
                                    return (
                                        <>
                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    id="person"
                                                    label="Person Name"
                                                    name="name"
                                                    value={item.name}
                                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <TextField
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    id="number"
                                                    label="Person Number"
                                                    name="number"
                                                    value={item.number}
                                                    inputProps={{ minLength: 3, maxLength: 13 }}
                                                    onChange={e => handleInputChange(e, i)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} lg={4}>
                                                <Grid
                                                    container
                                                    spacing={1}
                                                    direction="row"
                                                >
                                                    <Grid item xs={11} lg={11}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            id="email"
                                                            label="Person Email"
                                                            name="email"
                                                            value={item.email}
                                                            inputProps={{ maxLength: 150 }}
                                                            onChange={e => handleInputChange(e, i)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} lg={1}>
                                                        <Tooltip title="Delete">
                                                            <IconButton onClick={() => handleRemoveClick(i)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </>
                                    );
                                })}
                                {/* <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="person"
                                    label="Person Name"
                                    name="person"
                                    value={personName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handlePersonNameChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="number"
                                    label="Person Number"
                                    name="number"
                                    value={personNumber}
                                    inputProps={{ minLength: 10, maxLength: 13 }}
                                    onChange={handlePersonNumberChange}
                                />
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="number"
                                    label="Person Email"
                                    name="number"
                                    value={personEmail}
                                    inputProps={{ minLength: 10, maxLength: 13 }}
                                    onChange={handlePersonEmailChange}
                                />
                            </Grid> */}

                                <Grid item xs={12} lg={12}>
                                    <div className="before pt-2">
                                        <h2 className="form-heading">Location Information</h2>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={countries}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={country}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="Country" required />}
                                        onChange={handleCountryChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={states}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={state}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="State" required />}
                                        onChange={handleStateChange}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={4}>
                                    <Autocomplete
                                        options={cities}
                                        autoHighlight
                                        getOptionLabel={(option) => option.name}
                                        value={city}
                                        renderInput={(params) => <TextField {...params} variant="outlined" label="City" required />}
                                        onChange={handleCityChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Link to="/dashboard/crm/leads">
                                        <Button
                                            type="submit"
                                            variant="contained"
                                        >
                                            Back
                                        </Button>
                                    </Link>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        disabled={loading}
                                    >
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default EditLead
