import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Divider,
    Grid,
    Link as MLink,
    ListItem,
    TextField,
    Typography
} from '@material-ui/core'

import axios from 'axios'
import config from '../../config'
import SendIcon from '@material-ui/icons/Send';
import InfiniteScroll from 'react-infinite-scroll-component'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { dateTimeFormate, stringToDateTime } from '../../functions'
import moment from 'moment'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewMessages = (props) => {
    const token = localStorage.getItem('bsToken')
    const user = JSON.parse(localStorage.getItem('bsUser'))
    const limit = 10
    const ticketId = props.match.params.ticketId
    const [customer, setCustomer] = useState('')
    const [id, setId] = useState('')
    const [type, setType] = useState('')
    const [status, setStatus] = useState('')
    const [title, setTitle] = useState('')
    const [date, setDate] = useState('')
    const [messages, setMessages] = useState([])
    const [message, setMessage] = useState('')
    const [start, setStart] = useState(0)
    const [disabled, setDisabled] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    const getTicket = () => {
        const url = config.url + '/tickets/' + ticketId
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            const ticket = response.data
            setId(ticket.ticket_id)
            setCustomer(ticket.customer)
            setType(ticket.type.name)
            setStatus(ticket.status)
            setTitle(ticket.title)
            setDate(ticket.created_at)
            if(ticket.status !== 'Open') {
                setDisabled(true)
            }
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getList = (start = 0, limit = 10) => {
        const params = {
            start,
            limit
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/tickets/' + ticketId + '/messages'
        axios.get(url, headerConfig).then(response => {
            const messages = response.data
            setMessages(messages)
            const _start = start + limit
            setStart(_start)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getTicket()
        getList()
        // eslint-disable-next-line
    }, [])

    const getNext = () => {
        const params = {
            start: start,
            limit
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/tickets/' + ticketId + '/messages'
        axios.get(url, headerConfig).then(response => {
            const _messages = [...messages, ...response.data]
            setMessages(_messages)
            const _start = start + limit
            setStart(_start)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const date = moment()
        const _message = {
            mesaage: message,
            name: user.first_name,
            created_at: stringToDateTime(date)
        }

        const _messages = [_message, ...messages]
        setMessages(_messages)
        setMessage('')
        const data = {
            message
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/tickets/' + ticketId + '/messages'
        axios.post(url, data, headerConfig).then(response => {

        }).catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                } else {
                    console.log(error.response)
                }
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const handleMessageChange = (e) => {
        var value = e.target.value
        setMessage(value)
    }

    const closeTicket = () => {
        setDisabled(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        let message
        let path
        let newStatus
        if(status === 'Closed') {
            path = '/reopen'
            message = 'Ticket opened.'
            newStatus = 'Open'
        } else {
            path = '/close'
            message = 'Ticket closed.'
            newStatus = 'Closed'
        }

        const url = config.url + '/tickets/' + ticketId + path
        axios.put(url, {}, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage(message)
            setShowAlert(true)
            setStatus(newStatus)
            if(newStatus === 'Open') {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 403) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                } else {
                    console.log(error.response)
                }
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Messages</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/tickets">
                        Tickets
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <Grid container
                        className="mt-2"
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item >
                            <div className="before">
                                <h2 className="form-heading">Chat with {customer}</h2>
                            </div>
                        </Grid>
                        <Grid item className="mr-4">
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={closeTicket}
                            >
                                {(status === 'Closed') ? 'Reopen' : 'Close'} Ticket
                            </Button>
                        </Grid>
                    </Grid>
                    <hr />
                    <Grid
                        container
                        spacing={3}
                        direction="row"
                    >
                        <Grid item>
                            <p><b>Id: </b>{id}</p>
                        </Grid>
                        <Grid item>
                            <p><b>Type: </b>{type}</p>
                        </Grid>
                        <Grid item>
                            <p><b>Status: </b>{status}</p>
                        </Grid>
                        <Grid item>
                            <p><b>Ticket: </b>{title}</p>
                        </Grid>
                        <Grid item>
                            <p><b>Date: </b>{dateTimeFormate(date)}</p>
                        </Grid>
                    </Grid>
                    <hr />
            <Grid
                container
                spacing={3}
                direction="row"
            >
                <Grid item xs={12} >
                    <Grid
                        container
                        spacing={3}
                        direction="column"
                    >
                        <div
                            id="scrollableDiv"
                            style={{
                                height: 500,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column-reverse',
                            }}
                        >
                            {/*Put the scroll bar always on the bottom*/}
                            <InfiniteScroll
                                dataLength={messages.length}
                                next={getNext}
                                style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'unset' }} //To put endMessage and loader to the top.
                                inverse={true} //
                                hasMore={true}
                                // loader={<h4>Loading...</h4>}
                                scrollableTarget="scrollableDiv"
                            >
                                {
                                    messages.map((message, index) => {
                                        const justify = (message.self) ? 'flex-start' : 'flex-end'
                                        const chatBG = (message.self) ? 'chat-receiver' : 'chat-sender'
                                        return (
                                            <ListItem key={index}>
                                                <Grid
                                                    container
                                                    spacing={3}
                                                    direction="row"
                                                    justify={justify}
                                                >
                                                    <Grid item>
                                                        <span className="sender">{message.name}</span>
                                                        <Card className={chatBG}>
                                                            <CardContent className="msg-box">
                                                                {message.mesaage}
                                                                <br />
                                                            </CardContent>
                                                        </Card>
                                                        <span className="sender">{dateTimeFormate(message.created_at)}</span>
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                        )
                                    })
                                }
                            </InfiniteScroll>
                        </div>
                        <Divider />
                        <Grid item>
                            <form onSubmit={onSubmit} className="chat">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                >
                                    <Grid item xs={12}>

                                        <TextField
                                            required
                                            fullWidth
                                            variant="outlined"
                                            id="message"
                                            label="Message"
                                            name="message"
                                            value={message}
                                            inputProps={{ minLength: 2, maxLength: 150 }}
                                            onChange={handleMessageChange}
                                            disabled={disabled}
                                        />
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={disabled}
                                        >
                                            <SendIcon />
                                        </Button>

                                    </Grid>
                                    {/* <Grid item xs={1}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            disabled={disabled}
                                        >
                                            <SendIcon />
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </form>
                        </Grid>
                    </Grid>

                </Grid>
            </Grid >
                </CardContent>
            </Card>

        </div >
    )
}

export default ViewMessages
