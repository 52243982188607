import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

import { dateToString } from '../../../functions'
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns'
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddPayment = (props) => {
    const invoice = (props.location.state === null) ? {} : props.location.state
    const token = localStorage.getItem('bsToken')
    const paymentTypes = ['Bank', 'Cash', 'Cheque', 'Mobile', 'Online']
    const [date, setDate] = useState(moment())
    const [amount, setAmount] = useState(null)
    const [comment, setComment] = useState('')
    const [paymentType, setPaymentType] = useState(null)
    const [transactionNumber, setTransactionNumber] = useState('')

    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [file, setFile] = useState(null)
    const [fileData, setFileData] = useState(null)

    useEffect(() => {
        if (props.location.state === null) {
            props.history.push('/dashboard/invoices')
        }
        // eslint-disable-next-line
    }, [])

    const handleFileChange = (event) => {
        let files = event.target.files
        let file = files[0]
        if (file.type === 'application/pdf') {
            let fileInfo = {
                name: file.name,
                type: file.type,
                size: (file.size / 1000000).toFixed(2) + ' MB',
                file: file,
            }
            const info = fileInfo.size + ' - ' + file.name
            setFileData(info)
            setFile(fileInfo)
        } else {
            setFileData('Invalid File - ' + file.name)
        }
    }

    const handleDateChange = (selectedDate) => {
        const date = moment(selectedDate)
        setDate(date)
    }

    const handleAmountChange = (e) => {
        var value = e.target.value
        setAmount(value)
    }

    const handleCommentChange = (e) => {
        var value = e.target.value
        setComment(value)
    }

    const handlePaymentTypeChange = (e, value) => {
        setPaymentType(value)
    }

    const handleTransactionNumberChange = (e) => {
        var value = e.target.value
        setTransactionNumber(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if (!date.isValid()) {
            setAlertType('error')
            setAlertMessage('Invalid date.')
            setShowAlert(true)
        } else {
            setLoading(true)
            var _invoice_date = (date === null || date === '') ? '' : dateToString(date)
            var _comment = comment
            var _transaction_number = transactionNumber

            _comment = ltrim(_comment)
            _comment = rtrim(_comment)
            _transaction_number = ltrim(_transaction_number)
            _transaction_number = rtrim(_transaction_number)

            var formData = new FormData()
            formData.append('invoice_date', _invoice_date)
            formData.append('amount', amount)
            formData.append('comment', _comment)
            formData.append('payment_type', paymentType)
            formData.append('transaction_number', _transaction_number)
            if (file !== null) {
                formData.append('file', file.file)
            }

            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/invoices/' + invoice.id + '/payments'
            axios.post(url, formData, headerConfig).then(response => {
                setLoading(false)
                setAlertType('success')
                setAlertMessage('Invoice added.')
                setShowAlert(true)
                props.history.push('/dashboard/invoices/customer/' + invoice.customerId)
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add Invoice Payment</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/invoices">
                        Invoices
                    </MLink>
                    <MLink color="inherit" href={'/#/dashboard/invoices/customer/' + invoice.customerId}>
                        {invoice.name}'s Invoices
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Add Invoice Payment</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                >
                                    <Grid item xs={12}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                required
                                                disableFuture
                                                fullWidth
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                id="date-picker-inline"
                                                label="Invoice Date"
                                                value={date}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'Change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            disabled
                                            name="invoiceNumber"
                                            label="Invoice Number"
                                            id="invoiceNumber"
                                            value={invoice.invoiceNumber}
                                        // onChange={handleInvoiceNumberChange}
                                        // inputProps={{ minLength: 1, maxLength: 50 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            name="amount"
                                            label="Amount"
                                            id="amount"
                                            onChange={handleAmountChange}
                                            inputProps={{ minLength: 1, maxLength: 20, pattern: "([0-9]+[.])?[0-9]+", title: "Numbers only (Ex. 1 or 1.2)" }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            id="standard-multiline-static"
                                            label="Comment"
                                            onChange={handleCommentChange}
                                            inputProps={{ maxLength: 255 }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction="row"
                                >
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            disabled
                                            name="customer"
                                            label="Customer"
                                            id="invoiceNumber"
                                            value={invoice.name}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={paymentTypes}
                                            autoHighlight
                                            required
                                            getOptionLabel={(option) => option}
                                            renderInput={(params) => <TextField {...params} label="Payment Type" required />}
                                            onChange={handlePaymentTypeChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            name="transactionNumber"
                                            label="Transaction Number"
                                            id="transactionNumber"
                                            inputProps={{ maxLength: 50 }}
                                            onChange={handleTransactionNumberChange}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                            Upload Receipt
                                            <input
                                                type="file"
                                                // accept="image/png,image/jpeg,application/pdf"
                                                accept="application/pdf"
                                                hidden
                                                onChange={handleFileChange}
                                            />
                                        </Button> &nbsp;&nbsp;
                                        {fileData}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} style={{ textAlign: "center" }}>
                                <hr />
                                <Link to={'/dashboard/invoices/customer/' + invoice.customerId}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddPayment
