import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import MuiPhoneNumber from 'material-ui-phone-number'
import ProfileImage from '../../../assets/images/profile.png'
import PictureBase from '../../../components/PictureBase'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateUser = (props) => {
    const userId = props.match.params.userId
    const [roles, setRoles] = useState([])
    const [countries, setCountries] = useState([])
    const [states, setStates] = useState([])
    const [cities, setCities] = useState([])
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState(null)
    const [fileData, setFileData] = useState(null)
    const [role, setRole] = useState(null)
    const [country, setCountry] = useState(null)
    const [state, setState] = useState(null)
    const [city, setCity] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const token = localStorage.getItem('bsToken')
    const [profilePicture, setProfilePicture] = useState(ProfileImage)
    const [imageSet, setImageSet] = useState(false)

    const getRoles = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/roles/all'
        axios.get(url, headerConfig).then(response => {
            setRoles(response.data)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getUser = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/' + userId
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setRole(user.role)
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
            setNumber(user.number)
            setCountry(user.country)
            setState(user.state)
            setCity(user.city)
            setProfilePicture(user.url)
            defaultCountry(user.country, user.state)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const getCountries = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/countries'
        axios.get(url, headerConfig).then(response => {
            setCountries(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getRoles()
        getCountries()
        getUser()
        // eslint-disable-next-line
    }, [])

    const defaultCountry = (country, state) => {
        setCountry(country)
        getStates(country.id)
        setState(state)
        getCities(state.id)
    }

    const getStates = (country_id) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/states/' + country_id
        axios.get(url, headerConfig).then(response => {
            setStates(response.data)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const getCities = (state_id) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/location/cities/' + state_id
        axios.get(url, headerConfig).then(response => {
            setCities(response.data)
        }).catch(error => {
            let message = 'Try after sometime.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleCountryChange = (e, item) => {
        if (item !== null) {
            getStates(item.id)
        }
        setCountry(item)
        setState(null)
        setCity(null)
    }

    const handleStateChange = (e, item) => {
        setState(item)
        if (item !== null) {
            getCities(item.id)
        }
        setCity(null)
    }

    const handleCityChange = (e, item) => {
        setCity(item)
    }

    const handleFirstNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setFirstName(value)
    }

    const handleLastNameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setLastName(value)
    }

    const handleRoleChange = (e, role) => {
        setRole(role)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            first_name: firstName,
            last_name: lastName,
            role_id: role.id,
            city_id: city.id,
            number,
            image: (imageSet) ? profilePicture : '',
            image_type: (fileData !== null) ? fileData.type : ''
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/users/' + userId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('User updated.')
            setShowAlert(true)
            // props.history.push('/dashboard/settings/users')
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleNumberChange = (value) => {
        setNumber(value)
    }

    const handleFileChange = (file) => {
        let fileInfo = {
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
        }
        setFileData(fileInfo)
        setProfilePicture(file.base64)
        setImageSet(true)
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Update User</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/settings/users">
                        Users
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Update User</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="fName"
                                    label="First Name"
                                    name="fName"
                                    value={firstName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleFirstNameChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    required
                                    fullWidth
                                    id="lName"
                                    label="Last Name"
                                    name="lName"
                                    value={lastName}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={handleLastNameChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <MuiPhoneNumber
                                    onlyCountries={['in']}
                                    defaultCountry={'in'}
                                    autoFormat={false}
                                    disableAreaCodes={true}
                                    disableCountryCode={true}
                                    disableDropdown={true}
                                    required
                                    fullWidth
                                    id="number"
                                    label="Mobile Number"
                                    name="number"
                                    placeholder="Mobile number without +91"
                                    value={number}
                                    error={false}
                                    inputProps={{ minLength: 10, maxLength: 10 }}
                                    onChange={handleNumberChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    fullWidth
                                    id="email"
                                    label="Email"
                                    name="email"
                                    value={email}
                                    inputProps={{ minLength: 5, maxLength: 50, type: "email" }}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={roles}
                                    getOptionLabel={(option) => option.name}
                                    id="controlled-demo"
                                    value={role}
                                    autoHighlight
                                    onChange={handleRoleChange}
                                    renderInput={(params) => <TextField {...params} label="Role" required />}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <PictureBase
                                    multiple={false}
                                    onDone={handleFileChange}
                                />
                                <img src={profilePicture} className="ml-3" alt="Profile" style={{ width: '100px' }} />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={countries}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={country}
                                    renderInput={(params) => <TextField {...params} label="Country" required />}
                                    onChange={handleCountryChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={states}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={state}
                                    renderInput={(params) => <TextField {...params} label="State" required />}
                                    onChange={handleStateChange}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    options={cities}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    value={city}
                                    renderInput={(params) => <TextField {...params} label="City" required />}
                                    onChange={handleCityChange}
                                />
                            </Grid>
                            <Grid item xs={12} className="text-center">
                                <hr />
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                // disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateUser
