import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import ImageBase from '../../components/ImageBase'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddNews = (props) => {
    const token = localStorage.getItem('bsToken')
    const [categories, setCategories] = useState([])
    const [title, setTitle] = useState(null)
    const [content, setContent] = useState(null)
    const [category, setCategory] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [fileData, setFileData] = useState(null)
    const [file, setFile] = useState(null)
    const [pdfFileData, setPdfFileData] = useState(null)
    const [pdfFiles, setPdfFiles] = useState([])
    const [loading, setLoading] = useState(false)

    const getCategories = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/news/categories/all'
        axios.get(url, headerConfig).then(response => {
            setCategories(response.data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCategories()
        // eslint-disable-next-line
    }, [])

    const handleTitleChange = (e) => {
        var value = e.target.value
        // value = ltrim(value)
        // value = rtrim(value)
        setTitle(value)
    }

    const handleContentChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setContent(value)
    }

    const handleFileChange = (event) => {
        let files = event.target.files
        let file = files[0]
        let fileInfo = {
            name: file.name,
            type: file.type,
            size: (file.size / 1000000).toFixed(2) + ' MB',
            file: file,
        }
        const info = fileInfo.size + ' - ' + file.name
        setFileData(info)
        setFile(fileInfo)
    }

    const handleCategoryChange = (e, category) => {
        setCategory(category.id)
    }

    const getFile = (files) => {
        if (files.length === 1) {
            setPdfFileData(' - 1 File Selected.')
        } else if (files.length > 1) {
            setPdfFileData(' - ' + files.length + ' Files Selected.')
        } else {
            setPdfFileData('')
        }
        const pdfFiles = files.map(file => {
            return file.file
        })
        setPdfFiles(pdfFiles)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (file === null) {
            setAlertType('error')
            setAlertMessage('Please select a file.')
            setShowAlert(true)
            setLoading(false)
        } else {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            var formData = new FormData();
            formData.append("file", file.file)
            formData.append("title", title)
            formData.append("content", content)
            formData.append("category_id", category)
            pdfFiles.map(file => formData.append("attachments", file))
            const url = config.url + '/news'
            axios.post(url, formData, headerConfig).then(response => {
                setAlertType('success')
                setAlertMessage('News added.')
                setShowAlert(true)
                setLoading(false)
                props.history.push('/dashboard/news')
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Add News</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/news">
                        News
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Add News</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    id="title"
                                    label="Title"
                                    name="title"
                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                    onChange={handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    options={categories}
                                    autoHighlight
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Category" required />}
                                    onChange={handleCategoryChange}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    variant="contained"
                                    component="label"
                                >
                                    Select Image
                                    <input
                                        type="file"
                                        accept="image/png,image/jpeg"
                                        hidden
                                        onChange={handleFileChange}
                                    />
                                </Button> &nbsp;&nbsp;
                                {fileData}
                                <br />
                                <br />
                                image size should be 600x500 px
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                PDF Attachments {pdfFileData}
                                <ImageBase
                                    multiple={true}
                                    onDone={getFile}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="content"
                                    label="Content"
                                    name="content"
                                    multiline
                                    rows={15}
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ minLength: 3, maxLength: 50000 }}
                                    onChange={handleContentChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <hr />
                                <Link to="/dashboard/news">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddNews
