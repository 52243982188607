import React, { useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Grid,
    Link as MLink,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Link } from 'react-router-dom'

import Customers from './Customers'
import DisabledCustomers from './DisabledCustomers'

const ListCustomers = () => {
    const [value, setValue] = useState(0)
    const [content, setContent] = useState(<Customers />)

    const handleChange = (event, newValue) => {
        const content = (newValue === 0) ? <Customers /> : <DisabledCustomers />
        setContent(content)
        setValue(newValue)
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Clients</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="customers/add"><Button variant="contained" color="primary"><AddIcon />Add Client</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Tabs
                className="entry-div"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Clients" />
                <Tab label="Disabled Clients" />
            </Tabs>
            {content}
        </div>
    )
}

export default ListCustomers
