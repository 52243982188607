import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Grid,
    Link as MLink,
    Snackbar,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import config from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateDocumentType = (props) => {
    const token = localStorage.getItem('bsToken')
    const typeId = props.match.params.typeId
    const [name, setName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')


    const getRole = () => {
        const url = config.url + '/document-types/' + typeId
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setName(response.data.name)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getRole()
        // eslint-disable-next-line
    }, [])

    const nameChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        const data = {
            name
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/document-types/' + typeId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Document type updated')
            setShowAlert(true)
            // props.history.push('/dashboard/settings/roles')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                console.log(error.response)
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else if (error.response.status === 409) {
                    message = error.response.data.detail
                }
                else {
                    message = error.response.data.detail[0].msg
                }
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Update Document Type</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/document-types">
                        Document Types
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Update Document Type</h2>
                    </div>
                    <hr />
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <Grid item xs={3}>
                                <TextField
                                    required
                                    fullWidth
                                    id="name"
                                    label="Document Type Name"
                                    name="name"
                                    value={name}
                                    inputProps={{ minLength: 3, maxLength: 50 }}
                                    onChange={nameChange}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Link to="/dashboard/document-types">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateDocumentType
