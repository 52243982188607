import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import axios from 'axios'
import config from '../../../../config'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UserDetails = () => {
    const token = localStorage.getItem('bsToken')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [number, setNumber] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [role, setRole] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    useEffect(() => {
        getProfile()
        // eslint-disable-next-line
    }, [])

    const getProfile = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/user'
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setFirstName(user.first_name)
            setLastName(user.last_name)
            setEmail(user.email)
            setNumber(user.number)
            setCountry(user.country.name)
            setState(user.state.name)
            setCity(user.city.name)
            setRole(user.role.name)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                } else {
                    console.log(error.response)
                }
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div>
                <Row className="justify-content-md-center">
                    <Col md={12} className="p-unset">
                        <div>
                            <Row className="border">
                                <Col md={6}>
                                    <b>First Name: </b> {firstName}
                                </Col>
                                <Col md={6}>
                                    <b>Last Name: </b> {lastName}
                                </Col>
                            </Row>
                            <Row className="border">
                                <Col md={6}>
                                    <b>Mobile No: </b> {number}
                                </Col>
                                <Col md={6}>
                                    <b>Email Id: </b> {email}
                                </Col>
                            </Row>
                            <Row className="border">
                                <Col md={6}>
                                    <b>Country: </b> {country}
                                </Col>
                                <Col md={6}>
                                    <b>State: </b> {state}
                                </Col>
                            </Row>
                            <Row className="border">
                                <Col md={6}>
                                    <b>City: </b> {city}
                                </Col>
                                <Col md={6}>
                                    <b>User Role: </b> {role}
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default UserDetails
