import React, { useEffect, useState } from 'react'
import {
    AppBar,
    Badge,
    Box,
    CssBaseline,
    Divider,
    Drawer,
    Grid,
    Hidden,
    IconButton,
    ListItem,
    ListItemText,
    Popover,
    Snackbar,
    Toolbar,
    Tooltip,
    Typography
} from '@material-ui/core'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import NotificationsIcon from '@material-ui/icons/Notifications'

import { Link, Redirect, Route, Switch } from 'react-router-dom'

import PropTypes from 'prop-types'

import ProfileMenu from './ProfileMenu'
import SideNav from './SideNav'
import Dashboard from '../pages/Dashboard'
import ListUsers from '../pages/settings/users/ListUsers'
import ListRoles from '../pages/settings/roles/ListRoles'
import AddUser from '../pages/settings/users/AddUser'
import UpdateUser from '../pages/settings/users/UpdateUser'
import AddRole from '../pages/settings/roles/AddRole'
import UpdateRole from '../pages/settings/roles/UpdateRole'
import Profile from '../pages/settings/users/profile/Profile'
import EditProfile from '../pages/settings/users/profile/EditProfile'
import MuiAlert from '@material-ui/lab/Alert'
import Unauthorized from '../pages/authentication/Unauthorized'
import ResetPassword from '../pages/settings/users/ResetPassword'
import ListCustomers from '../pages/customers/ListCustomers'
import ListFolders from '../pages/customers/folders/ListFolders'
import AddFolder from '../pages/customers/folders/AddFolder'
import EditFolder from '../pages/customers/folders/EditFolder'
import OpenFolder from '../pages/customers/folders/OpenFolder'
import ListDocuments from '../pages/customers/documents/ListDocuments'
import AddDocument from '../pages/customers/documents/AddDocument'
import EditDocument from '../pages/customers/documents/EditDocument'
import AddCustomer from '../pages/customers/AddCustomer'
import UpdateCustomer from '../pages/customers/UpdateCustomer'
import ListMembers from '../pages/customers/members/ListMembers'
import AddMember from '../pages/customers/members/AddMember'
import UpdateMember from '../pages/customers/members/UpdateMember'
import CustomerResetPassword from '../pages/customers/ResetPassword'
import ListNews from '../pages/news/ListNews'
import AddNews from '../pages/news/AddNews'
import UpdateNews from '../pages/news/UpdateNews'
import ListNewsCategory from '../pages/news/categories/ListNewsCategory'
import AddNewsCategory from '../pages/news/categories/AddNewsCategory'
import UpdateNewsCategory from '../pages/news/categories/UpdateNewsCategory'
import ListInvoices from '../pages/invoices/ListInvoices'
import ListCustomerInvoices from '../pages/invoices/ListCustomerInvoices'
import PaymentReports2 from '../pages/invoices/payment/PaymentReports'
import AddInvoice from '../pages/invoices/AddInvoice'
import UpdateInvoice from '../pages/invoices/UpdateInvoice'
// import ListPayments from '../pages/invoices/payment/ListPayments'
import PaymentReports from '../pages/payments/PaymentReports'
import AddPayment from '../pages/invoices/payment/AddPayment'
import UpdatePayment from '../pages/invoices/payment/UpdatePayment'
import ListDocumentTypes from '../pages/document_types/ListDocumentTypes'
import AddDocumentType from '../pages/document_types/AddDocumentType'
import UpdateDocumentType from '../pages/document_types/UpdateDocumentType'
import ListFaqs from '../pages/faqs/ListFaqs'
import AddFaq from '../pages/faqs/AddFaq'
import UpdateFaq from '../pages/faqs/UpdateFaq'
import ListTickets from '../pages/tickets/ListTickets'
import AddTicket from '../pages/tickets/AddTicket'
import ViewMessages from '../pages/tickets/ViewMessages'
import ListBanners from '../pages/settings/banners/ListBanners'
import AddBanner from '../pages/settings/banners/AddBanner'
import ListTicketTypes from '../pages/ticket_types/ListTicketTypes'
import AddTicketType from '../pages/ticket_types/AddTicketType'
import UpdateTicketType from '../pages/ticket_types/UpdateTicketType'
import UpdateVersion from '../pages/settings/versions/UpdateVersion'

import ListLeads from '../pages/crm/leads/ListLeads'
import AddLead from '../pages/crm/leads/AddLead'
import ViewLead from '../pages/crm/leads/ViewLead'
import EditLead from '../pages/crm/leads/EditLead'
// import ListLogs from '../pages/crm/logs/ListLogs'
import ListDeletedLeads from '../pages/crm/leads/ListDeletedLeads'

import ListServices from '../pages/crm/services/ListServices'
import AddService from '../pages/crm/services/AddService'
import UpdateService from '../pages/crm/services/UpdateService'

import ListSources from '../pages/crm/sources/ListSources'
import AddSource from '../pages/crm/sources/AddSource'
import UpdateSource from '../pages/crm/sources/UpdateSource'

import ListPendingFollowup from '../pages/crm/leads/view/ListPendingFollowup'
import ListTodayFollowup from '../pages/crm/leads/view/ListTodayFollowup'

import ListLeadCustomers from '../pages/crm/customers/ListLeadCustomers'
import ViewLeadCustomer from '../pages/crm/customers/ViewLeadCustomer'

import EditFollowup from '../pages/crm/leads/view/EditFollowup'

import ListNotifications from '../pages/ListNotifications'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import axios from 'axios'
import config from '../config'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const drawerWidth = 240
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function ResponsiveDrawer(props) {
    const { window } = props
    const classes = useStyles()
    const theme = useTheme()
    const token = localStorage.getItem('bsToken')
    const MINUTE_MS = 60000
    const operations = JSON.parse(localStorage.getItem('bsOperations'))
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [notificationMenu, setNotificationMenu] = useState(null)
    const [notifications, setNotifications] = useState([])
    const [count, setCount] = useState(0)

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const getOperations = () => {
        if (token !== null) {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            const url = config.url + '/operations'
            axios.get(url, headerConfig).then(response => {
                const operations = response.data.operations
                localStorage.setItem('bsOperations', JSON.stringify(operations))
            }).catch(error => {
                if (error.response) {
                    if (error.response.status === 402) {
                        localStorage.clear()
                        props.history.push('/login')
                    } else {
                        console.log(error.response)
                    }
                } else if (error.request) {
                    console.log(error.request)
                } else {
                    console.log(error)
                }
            })
        }
    }

    const getCount = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/notifications/count'
        axios.get(url, headerConfig).then(response => {
            setCount(response.data.count)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        if (token === null) {
            var location = '/login'
            props.history.push(location)
        }
        getOperations()
        getCount()
        const interval = setInterval(() => {
            getCount()
        }, MINUTE_MS);

        return () => clearInterval(interval);
        // eslint-disable-next-line
    }, [])

    const getList = () => {
        const params = {
            recent: true
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/notifications'
        axios.get(url, headerConfig).then(response => {
            setNotifications(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const isRead = (itemId) => {
        const params = {
            recent: true
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/notifications/' + itemId
        axios.put(url, {}, headerConfig).then(response => {
            const _count = count - 1
            setCount(_count)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    const checkOperations = (operation) => {
        const status = operations.includes(operation)
        if (status === false) {
            setAlertType('error')
            setAlertMessage('You don\'t have permission.')
            setShowAlert(true)
        }
        return status
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleMenuButtonClick = (event) => {
        setNotificationMenu(event.currentTarget);
        getList()
    };

    const handleMenuClose = () => {
        setNotificationMenu(null);
    };

    const menuOpen = Boolean(notificationMenu);
    const id = mobileOpen ? 'simple-popover' : undefined;

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                    {/* <Typography variant="h6" noWrap>
                        Responsive drawer
                    </Typography> */}
                    <Grid container spacing={3}>
                        <Grid item xs={11}></Grid>
                    </Grid>
                    <Tooltip title="Notifications">
                        <IconButton onClick={handleMenuButtonClick}>
                            <Badge badgeContent={count} max={1000} color="success">
                                <NotificationsIcon style={{ color: "white" }} />
                            </Badge>
                        </IconButton>
                    </Tooltip>
                    <Popover
                        id={id}
                        open={menuOpen}
                        anchorEl={notificationMenu}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Box m={2}>
                            <Typography variant="h5" component="h3" className="notification-title">
                                You have {count} new notification
                            </Typography>
                        </Box>
                        <Divider />
                        <div style={{ width: 300, maxWidth: 500 }}>
                            {notifications.map((item, index) => {
                                return (
                                    <Link key={index} to={item.url} className={classes.sideLinks}>
                                        <ListItem button onClick={() => {
                                            handleMenuClose()
                                            if (!item.is_read) {
                                                isRead(item.id)
                                            }
                                        }}>
                                            {/* <span className="notification-badge-unread">•</span> &nbsp; */}
                                            <div className="bg-notification mr-3">
                                                <NotificationsIcon style={{ color: "#FFF" }} fontSize="small" />
                                            </div>
                                            <div>
                                                {item.message}
                                                <div className="ago">{item.created_at}</div>
                                            </div>
                                        </ListItem>
                                    </Link>
                                )
                            })}

                            {/* <Link to="/dashboard/notifications" className={classes.sideLinks}>
                                <ListItem button onClick={handleMenuClose}>
                                    <span className="notification-badge-read">•</span> &nbsp;
                                    <Grid container spacing={3}>
                                        <Grid item xs={9}>New lead assigned </Grid>
                                        <Grid item xs={3} style={{ textAlign: "right" }}>3 months</Grid>
                                    </Grid>
                                </ListItem>
                            </Link> */}
                        </div>
                        <Divider />
                        <Box >
                            <Link to="/dashboard/notifications" className={classes.sideLinks}>
                                <ListItem button onClick={handleMenuClose}>
                                    <ListItemText primary="See all Notifications" /> <ArrowForwardIosIcon fontSize="inherit" />
                                </ListItem>
                            </Link>
                        </Box>

                    </Popover>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <SideNav />
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        <div className="bg-1">
                            <table className="w-100 table-menu">
                                <tr>
                                    <td className="w-50 pl-3 pt-3">CRM</td>
                                </tr>
                            </table>
                        </div>
                        <SideNav />
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route exact path="/dashboard" component={Dashboard} />
                    <Route exact path="/dashboard/profile" component={Profile} />
                    <Route exact path="/dashboard/profile/edit" component={EditProfile} />
                    <Route exact path="/dashboard/notifications" component={ListNotifications} />
                    <Route exact path="/dashboard/unauthorized" component={Unauthorized} />

                    <Route exact path="/dashboard/customers"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListCustomers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/add"
                        render={(props) => {
                            var status = checkOperations('Add Customer')
                            if (status === true) {
                                return (<AddCustomer {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<UpdateCustomer {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/customers/:customerId/members"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListMembers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListFolders {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/add"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<AddFolder {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<EditFolder {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId/open"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<OpenFolder {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId/open/add"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<AddFolder {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId/documents"
                        render={(props) => {
                            var status = checkOperations('List Customers')
                            if (status === true) {
                                return (<ListDocuments {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId/documents/add"
                        render={(props) => {
                            var status = checkOperations('Add Customer')
                            if (status === true) {
                                return (<AddDocument {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/folders/:folderId/documents/:documentId"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<EditDocument {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/customers/:customerId/members/add"
                        render={(props) => {
                            var status = checkOperations('Add Customer')
                            if (status === true) {
                                return (<AddMember {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/customers/:customerId/members/:memberId"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<UpdateMember {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/customers/reset-password/:customerId"
                        render={(props) => {
                            var status = checkOperations('Update Customer')
                            if (status === true) {
                                return (<CustomerResetPassword {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/news"
                        render={(props) => {
                            var status = checkOperations('List News')
                            if (status === true) {
                                return (<ListNews {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/news/add"
                        render={(props) => {
                            var status = checkOperations('Add News')
                            if (status === true) {
                                return (<AddNews {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/news/:newsId"
                        render={(props) => {
                            var status = checkOperations('Update News')
                            if (status === true) {
                                return (<UpdateNews {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/news-categories"
                        render={(props) => {
                            var status = checkOperations('List News Categories')
                            if (status === true) {
                                return (<ListNewsCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/news-categories/add"
                        render={(props) => {
                            var status = checkOperations('Add News Category')
                            if (status === true) {
                                return (<AddNewsCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/news-categories/:categoryId"
                        render={(props) => {
                            var status = checkOperations('Update News Category')
                            if (status === true) {
                                return (<UpdateNewsCategory {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/invoices"
                        render={(props) => {
                            var status = checkOperations('List Invoices')
                            if (status === true) {
                                return (<ListInvoices {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/add"
                        render={(props) => {
                            var status = checkOperations('Add Invoice')
                            if (status === true) {
                                return (<AddInvoice {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/:invoiceId"
                        render={(props) => {
                            var status = checkOperations('Update Invoice')
                            if (status === true) {
                                return (<UpdateInvoice {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/customer/:customerId"
                        render={(props) => {
                            var status = checkOperations('List Invoices')
                            if (status === true) {
                                return (<ListCustomerInvoices {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/customer/:customerId/payments"
                        render={(props) => {
                            var status = checkOperations('List Invoices')
                            if (status === true) {
                                return (<PaymentReports2 {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/payments"
                        render={(props) => {
                            var status = checkOperations('List Payments')
                            if (status === true) {
                                return (<PaymentReports {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/payments/add"
                        render={(props) => {
                            var status = checkOperations('Add Payment')
                            if (status === true) {
                                return (<AddPayment {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/invoices/payments/:paymentId"
                        render={(props) => {
                            var status = checkOperations('Update Payment')
                            if (status === true) {
                                return (<UpdatePayment {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/document-types"
                        render={(props) => {
                            var status = checkOperations('List Document Types')
                            if (status === true) {
                                return (<ListDocumentTypes {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/document-types/add"
                        render={(props) => {
                            var status = checkOperations('Add Document Type')
                            if (status === true) {
                                return (<AddDocumentType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/document-types/:typeId"
                        render={(props) => {
                            var status = checkOperations('Update Document Type')
                            if (status === true) {
                                return (<UpdateDocumentType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/faqs"
                        render={(props) => {
                            var status = checkOperations('List FAQs')
                            if (status === true) {
                                return (<ListFaqs {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/faqs/add"
                        render={(props) => {
                            var status = checkOperations('Add FAQ')
                            if (status === true) {
                                return (<AddFaq {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/faqs/:faqId"
                        render={(props) => {
                            var status = checkOperations('Update FAQ')
                            if (status === true) {
                                return (<UpdateFaq {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/tickets"
                        render={(props) => {
                            var status = checkOperations('List Tickets')
                            if (status === true) {
                                return (<ListTickets {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/tickets/add"
                        render={(props) => {
                            var status = checkOperations('Add Ticket')
                            if (status === true) {
                                return (<AddTicket {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/tickets/:ticketId"
                        render={(props) => {
                            var status = checkOperations('Update Ticket')
                            if (status === true) {
                                return (<ViewMessages {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/ticket-types"
                        render={(props) => {
                            var status = checkOperations('List Ticket Types')
                            if (status === true) {
                                return (<ListTicketTypes {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/ticket-types/add"
                        render={(props) => {
                            var status = checkOperations('Add Ticket Type')
                            if (status === true) {
                                return (<AddTicketType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/ticket-types/:typeId"
                        render={(props) => {
                            var status = checkOperations('Update Ticket Type')
                            if (status === true) {
                                return (<UpdateTicketType {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/crm/leads"
                        render={(props) => {
                            var status = checkOperations('List Leads')
                            if (status === true) {
                                return (<ListLeads {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/leads/deleted"
                        render={(props) => {
                            var status = checkOperations('List Leads')
                            if (status === true) {
                                return (<ListDeletedLeads {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/leads/add"
                        render={(props) => {
                            var status = checkOperations('Add Lead')
                            if (status === true) {
                                return (<AddLead {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/leads/:leadId"
                        render={(props) => {
                            var status = checkOperations('Update Lead')
                            if (status === true) {
                                return (<ViewLead {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/leads/:leadId/edit"
                        render={(props) => {
                            var status = checkOperations('Update Lead')
                            if (status === true) {
                                return (<EditLead {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    {/* <Route exact path="/dashboard/crm/leads/:leadId/logs"
                        render={(props) => {
                            var status = checkOperations('List Users')
                            if (status === true) {
                                return (<ListUsers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                            return (<ListLogs {...props} />)
                        }}
                    /> */}
                    <Route exact path="/dashboard/crm/leads/:leadId/followup/:followupId/edit"
                        render={(props) => {
                            var status = checkOperations('Update Lead')
                            if (status === true) {
                                return (<EditFollowup {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/followups/pending"
                        render={(props) => {
                            var status = checkOperations('List Leads')
                            if (status === true) {
                                return (<ListPendingFollowup {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/followups/today"
                        render={(props) => {
                            var status = checkOperations('List Leads')
                            if (status === true) {
                                return (<ListTodayFollowup {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/crm/customers"
                        render={(props) => {
                            var status = checkOperations('List CRM Customers')
                            if (status === true) {
                                return (<ListLeadCustomers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/customers/:customerId"
                        render={(props) => {
                            var status = checkOperations('View CRM Customer')
                            if (status === true) {
                                return (<ViewLeadCustomer {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/crm/services"
                        render={(props) => {
                            var status = checkOperations('List Services')
                            if (status === true) {
                                return (<ListServices {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/services/add"
                        render={(props) => {
                            var status = checkOperations('Add Service')
                            if (status === true) {
                                return (<AddService {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/services/:serviceId"
                        render={(props) => {
                            var status = checkOperations('Update Service')
                            if (status === true) {
                                return (<UpdateService {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/crm/sources"
                        render={(props) => {
                            var status = checkOperations('List Lead Sources')
                            if (status === true) {
                                return (<ListSources {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/sources/add"
                        render={(props) => {
                            var status = checkOperations('Add Lead Source')
                            if (status === true) {
                                return (<AddSource {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/crm/sources/:sourceId"
                        render={(props) => {
                            var status = checkOperations('Update Lead Source')
                            if (status === true) {
                                return (<UpdateSource {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/settings/users"
                        render={(props) => {
                            var status = checkOperations('List Users')
                            if (status === true) {
                                return (<ListUsers {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/add"
                        render={(props) => {
                            var status = checkOperations('Add User')
                            if (status === true) {
                                return (<AddUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/:userId"
                        render={(props) => {
                            var status = checkOperations('Update User')
                            if (status === true) {
                                return (<UpdateUser {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/users/reset-password/:userId"
                        render={(props) => {
                            var status = checkOperations('Reset Password')
                            if (status === true) {
                                return (<ResetPassword {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles"
                        render={(props) => {
                            var status = checkOperations('List Roles')
                            if (status === true) {
                                return (<ListRoles {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/add"
                        render={(props) => {
                            var status = checkOperations('Add Role')
                            if (status === true) {
                                return (<AddRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/roles/:roleId"
                        render={(props) => {
                            var status = checkOperations('Update Role')
                            if (status === true) {
                                return (<UpdateRole {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/settings/banners"
                        render={(props) => {
                            var status = checkOperations('List Banners')
                            if (status === true) {
                                return (<ListBanners {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                    <Route exact path="/dashboard/settings/banners/add"
                        render={(props) => {
                            var status = checkOperations('Add Banner')
                            if (status === true) {
                                return (<AddBanner {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />

                    <Route exact path="/dashboard/settings/version"
                        render={(props) => {
                            var status = checkOperations('Update Version')
                            if (status === true) {
                                return (<UpdateVersion {...props} />)
                            }
                            else {
                                return (<Redirect to='/dashboard/unauthorized' />)
                            }
                        }}
                    />
                </Switch>
            </main>
        </div>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;
