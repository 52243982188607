import React, { useEffect, useState } from 'react'
import {
    Button,
    Breadcrumbs,
    Card,
    CardContent,
    Link as MLink,
    Grid,
    Tab,
    Tabs,
    Typography
} from '@material-ui/core'
import { Container, Row, Col } from 'react-bootstrap';
import UserDetails from './UserDetails'
import ChangePassword from './ChangePassword'
import ProfileImg from '../../../../assets/images/profile.png'
import { Link } from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit'

const Profile = () => {
    const _user = JSON.parse(localStorage.getItem('bsUser'))
    const user = (_user === null) ? {} : _user
    const [value, setValue] = useState(0)
    const [content, setContent] = useState(<UserDetails />)
    const [image, setImage] = useState(ProfileImg)

    useEffect(() => {
        setImage(user.url)
        // eslint-disable-next-line
    }, [])

    const handleChange = (event, newValue) => {
        const content = (newValue === 0) ? <UserDetails /> : <ChangePassword />
        setContent(content)
        setValue(newValue)
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Profile</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="/dashboard/profile/edit"><Button variant="contained" color="primary"><EditIcon fontSize="small" className="mr-2" />Edit Profile</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Card className="entry-div">
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">My Profile</h2>
                    </div>
                    <hr /><br />
                </CardContent>
            </Card>
            <Card className="entry-div">
                <CardContent>
                    <Container className="view-profile pb-4">
                        <Row className="justify-content-md-center">
                            <Col md={12} className="p-unset">
                                <div className="header-bg"></div>
                                <div className="profile-image">
                                    <img src={image} alt="Profile" style={{width: '100px', height: '100px'}}/>
                                </div>
                            </Col>
                        </Row>
                        <Tabs
                            className="mt-3"
                            value={value}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            <Tab label="Personal Information" />
                            <Tab label="Change Password" />
                        </Tabs>
                        {content}
                    </Container>
                </CardContent>
            </Card>
            {/* <Tabs
                className="entry-div"
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
            >
                <Tab label="Personal Information" />
                <Tab label="Change Password" />
            </Tabs>
            {content} */}
        </div>
    )
}

export default Profile
