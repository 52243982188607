import React, { useEffect, useRef, useState } from 'react'
import {
    Breadcrumbs,
    // Button,
    Card,
    CardContent,
    Grid,
    LinearProgress,
    Link as MLink,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import config from '../../../config'
// import AddIcon from '@material-ui/icons/Add'
// import { Link } from 'react-router-dom'

import Invoices from '../../payments/Invoices'
import Payments from '../../payments/Payments'

const PaymentReports = (props) => {
    const customerId = props.match.params.customerId
    const invoiceRef = useRef(null)
    const paymentRef = useRef(null)
    const token = localStorage.getItem('bsToken')
    const [loading, setLoading] = useState(false)
    const [totalAmount, setTotalAmount] = useState(0)
    const [totalPaid, setTotalPaid] = useState(0)
    const [totalPending, setTotalPending] = useState(0)
    const [customer, setCustomer] = useState({})

    const getTotal = (customerId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId + '/total'
        axios.get(url, headerConfig).then(response => {
            const amount = response.data
            setTotalAmount(amount.total_amount)
            setTotalPaid(amount.total_paid)
            setTotalPending(amount.total_pending)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    const getCustomer = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const data = response.data
            const name = data.first_name + ' ' + data.last_name
            const customer = {
                id: customerId,
                name
            }
            setCustomer(customer)
            // setLoading(false)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomer()
        getTotal(customerId)
        invoiceRef.current.updateInvoiceTable(customerId)
        paymentRef.current.updatePaymentsTable(customerId)
        // eslint-disable-next-line
    }, [])

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>{customer.name}'s Payments</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                    <MLink color="inherit" href="/#/dashboard/invoices">
                        Invoices
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <div className="entry-div">
                {progress}
                <Grid container
                    direction="row"
                    justify="space-between"
                    spacing={1}
                >
                    <Grid item xs={6}><Invoices ref={invoiceRef} /></Grid>
                    <Grid item xs={6}><Payments ref={paymentRef} /></Grid>
                </Grid>
            </div>
            <br />
            <Card>
                <CardContent>
                    <div className="before">
                        <h2 className="form-heading">Total Payment To Receive</h2>
                    </div>
                    <hr />
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Invoice Amount</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalAmount}</b></p>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Paid Amount</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalPaid}</b></p>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item xs={2}>
                            <p>Total Pending Payment</p>
                        </Grid>
                        <Grid item xs={10}>
                            <p>: &nbsp;<b>₹ {totalPending}</b></p>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}

export default PaymentReports
