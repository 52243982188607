import moment from 'moment'

export function dateToString(date) {
    return date.format('yyyy-MM-DD')
}

export function stringToDate(date) {
    return moment(date, 'yyyy-MM-DD')
}

export function stringToDateTime(date) {
    return moment(date, 'yyyy-MM-DD hh:mm:ss')
}

export function stringToTime(date) {
    return moment(date, 'HH:mm:ss')
}

export function dateFormate(date) {
    const mDate = stringToDate(date)
    return mDate.format('DD/MM/yyyy')
}

export function dateTimeFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('DD/MM/yyyy hh:mm A')
}

export function timeFormate(date) {
    const mDate = stringToTime(date)
    return mDate.format('hh:mm A')
}

export function dataToMoment(date) {
    return moment(date)
}

export function timeToString(date) {
    return date.format('HH:mm:ss')
}

export function indianNumberFormat(number) {
    return new Intl.NumberFormat('en-IN', { maximumSignificantDigits: 3 }).format(number)
}
