import React, { useState, useEffect } from 'react'
import {
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import config from '../../config'
import AddIcon from '@material-ui/icons/Add'
import DescriptionIcon from '@material-ui/icons/Description'
import DeleteIcon from '@material-ui/icons/Delete'
import MuiAlert from '@material-ui/lab/Alert'

import ImageBase from '../../components/ImageBase'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListNewsAttachments = (props) => {
    const token = localStorage.getItem('bsToken')
    const newsId = props.newsId
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [open, setOpen] = useState(false)
    const [userId, setUserId] = useState(null)
    const [loading, setLoading] = useState(false)

    const [openDialog, setOpenDialog] = useState(false)
    const [pdfFileData, setPdfFileData] = useState(null)
    const [pdfFiles, setPdfFiles] = useState([])

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "url",
            label: "File",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    const viewButton = (
                        <MLink href={value} target='_blank'>
                            <Tooltip title="View">
                                <IconButton>
                                    <DescriptionIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </MLink>
                    )
                    return viewButton
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/news/' + newsId + '/attachments'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Tooltip title="Delete">
                                <IconButton onClick={() => handleClickOpen(item.id)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/news/attachments/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList(start, limit, sortBy, order, search)
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const uploadAttachments = () => {
        setLoading(true)
        if (pdfFiles.length === 0) {
            setAlertType('error')
            setAlertMessage('Please select a file.')
            setShowAlert(true)
            setLoading(false)
        } else {
            const headerConfig = {
                headers: {
                    'accept': 'application/json',
                    'Content-Type': 'application/json',
                    token
                }
            }
            var formData = new FormData();
            pdfFiles.map(file => formData.append("attachments", file))
            const url = config.url + '/news/' + newsId + '/attachments'
            axios.post(url, formData, headerConfig).then(response => {
                setAlertType('success')
                setAlertMessage('Uploaded.')
                setShowAlert(true)
                setLoading(false)
                setOpenDialog(false)
                setStart(0)
                getList()
            }).catch(error => {
                let message
                if (error.response) {
                    message = 'Try after some time.'
                    if (error.response.status === 401) {
                        message = error.response.data.detail
                    }
                    console.log(error.response)
                } else if (error.request) {
                    console.log(error.request)
                    message = 'Try after some time.'
                } else {
                    console.log(error)
                    message = 'Try after some time.'
                }
                setAlertType('error')
                setAlertMessage(message)
                setShowAlert(true)
                setLoading(false)
            })
        }
    }

    const getFile = (files) => {
        if (files.length === 1) {
            setPdfFileData('1 File Selected.')
        } else if (files.length > 1) {
            setPdfFileData('' + files.length + ' Files Selected.')
        } else {
            setPdfFileData('')
        }
        const pdfFiles = files.map(file => {
            return file.file
        })
        setPdfFiles(pdfFiles)
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (userId) => {
        setOpen(true)
        setUserId(userId)
    }

    const handleDialogOpen = (userId) => {
        setOpenDialog(true)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenDialog(false)
        setUserId(null)
        setPdfFiles([])
        setPdfFileData(null)
    }

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Attachment File</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Attachment File ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Close
                    </Button>
                    <Button onClick={() => deleteItem(userId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={openDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Upload Attachment File</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item >
                                <ImageBase
                                    multiple={true}
                                    onDone={getFile}
                                />
                            </Grid>
                            <Grid item>
                                {pdfFileData}
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Close
                    </Button>
                    <Button onClick={uploadAttachments} variant="outlined" disabled={loading}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>
            <div>
                <div className="before mt-3 mb-3">
                    <Grid container
                        className="mt-2"
                        direction="row"
                        justify="space-between"
                        alignItems="flex-start"
                    >
                        <Grid item >
                            <h2 className="form-heading">News Attachments</h2>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleDialogOpen}><AddIcon />Add Attachments</Button>
                        </Grid>
                    </Grid>
                </div>
                <Table
                    title={''}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
                <hr />
            </div>
        </div>
    )
}

export default ListNewsAttachments
