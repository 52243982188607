import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip,
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import config from '../../config'
// import EditIcon from '@material-ui/icons/Edit'
// import DeleteIcon from '@material-ui/icons/Delete'
import ReceiptIcon from '@material-ui/icons/Receipt'
// import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
// import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListInvoice = forwardRef((props, ref) => {
    const [customerId, setCustomerId] = useState(null)
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const token = localStorage.getItem('bsToken')
    const [open, setOpen] = React.useState(false)
    const [itemId, setItemId] = useState(null)

    const updateInvoiceTable = (customerId) => {
        if (customerId === null) {
            setTableData([])
        } else {
            getList(customerId)
        }
        setCustomerId(customerId)
    }

    useImperativeHandle(ref, () => {
        return {
            updateInvoiceTable: updateInvoiceTable
        }
    })

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Customer",
            options: {
                sort: true,
            }
        },
        // {
        //     name: "invoice_date",
        //     label: "Date",
        //     options: {
        //         sort: true,
        //     }
        // },
        {
            name: "invoice_number",
            label: "Invoice Number",
            options: {
                sort: true,
            }
        },
        {
            name: "amount",
            label: "Amount",
            options: {
                sort: true,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return '₹ ' + value
                }
            }
        },
        // {
        //     name: "comment",
        //     label: "Comment",
        //     options: {
        //         sort: false,
        //     }
        // },
        // {
        //     name: "payment_type",
        //     label: "Payment Type",
        //     options: {
        //         sort: true,
        //     }
        // },
        // {
        //     name: "transaction_number",
        //     label: "Transaction Number",
        //     options: {
        //         sort: true,
        //     }
        // },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (customerId, start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/customers/' + customerId + '/invoices'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(invoice => {
                // const paymentUrl = 'invoices/' + invoice.id + '/payments'
                const viewUrl = invoice.url
                // const editUrl = 'invoices/' + invoice.id
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            {/* <Link to={paymentUrl}>
                            <Tooltip title="Payments">
                                <IconButton>
                                    <AttachMoneyIcon />
                                </IconButton>
                            </Tooltip>
                        </Link> */}
                            <MLink href={viewUrl} target="_blank">
                                <Tooltip title="View Invoice">
                                    <IconButton>
                                        <ReceiptIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </MLink>
                            {/* <Link to={editUrl}>
                            <Tooltip title="Edit Invoice">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Delete Invoice">
                            <IconButton onClick={() => handleClickOpen(invoice.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip> */}
                        </ButtonGroup>
                    </div>
                )
                invoice.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        // getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(customerId, start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(customerId, start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(customerId, start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(customerId, start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/invoices/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Invoice deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    // const handleClickOpen = (itemId) => {
    //     setOpen(true)
    //     setItemId(itemId)
    // }

    const handleClose = () => {
        setOpen(false)
        setItemId(null)
    }


    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete Category"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Invoice ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Table
                title={'List Invoices'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
})

export default ListInvoice
