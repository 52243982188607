import React, { useEffect, useState } from 'react'
import {
    Avatar,
    Breadcrumbs,
    Card,
    CardContent,
    Grid,
    Typography
} from '@material-ui/core'
import BookIcon from '@material-ui/icons/Book'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import PieChartIcon from '@material-ui/icons/PieChart'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { indianNumberFormat } from '../functions'

import axios from 'axios'
import config from '../config'

import { Link } from "react-router-dom"

const useStyles = makeStyles((theme) =>
    createStyles({
        large: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#17A2B8"
        },
        cold: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#f1a649"
        },
        loss: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#ef6860"
        },
        hot: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#429ce1"
        },
        warm: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#6368c0"
        },
        received: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "#2fad3b"
        },
        transparent: {
            width: theme.spacing(10),
            height: theme.spacing(10),
            backgroundColor: "transparent"
        },
        largeIcon: {
            fontSize: 50
        },
        cardLink: {
            textDecoration: "None"
        }
    }),
);

const Dashboard = () => {
    const token = localStorage.getItem('bsToken')
    const classes = useStyles()
    const [counts, setCounts] = useState({
        total_leads: 0,
        hot_leads: 0,
        warm_leads: 0,
        cold_leads: 0,
        received_leads: 0,
        loss_leads: 0,
        total_amount: 0,
        hot_amount: 0,
        warm_amount: 0,
        cold_amount: 0,
        received_amount: 0,
        loss_amount: 0
    })

    const getData = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/dashboard/counts'
        axios.get(url, headerConfig).then(response => {
            setCounts(response.data)
        }).catch(error => {
            if (error.response) {
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Dashboard</h1></Typography>
                </Breadcrumbs>
                <p className="mt-2">
                    Welcome to BizSmart
                </p>
            </div>
            <Grid
                className="entry-div"
                container
                spacing="3"
            >
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/crm/leads' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.large}
                                        >
                                            <BookIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Total Leads
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.total_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.total_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/crm/leads?type=hot' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.hot}
                                        >
                                            <EqualizerIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Hot Leads
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.hot_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.hot_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/crm/leads?type=warm' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.warm}
                                        >
                                            <PieChartIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Warm Leads
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.warm_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.warm_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={3}>
                    <Link to='dashboard/crm/leads?type=cold' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.cold}
                                        >
                                            <AllInboxIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Cold Leads
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.cold_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.cold_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Link to='dashboard/crm/leads?type=received' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.received}
                                        >
                                            <TrendingUpIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Order Received
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.received_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.received_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Link to='dashboard/crm/leads?type=loss' className={classes.cardLink}>
                        <Card>
                            <CardContent>
                                <Grid
                                    container
                                    spacing={3}
                                    alignItems="center"
                                >
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            className={classes.loss}
                                        >
                                            <TrendingDownIcon className={classes.largeIcon} />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            color="textSecondary"
                                            gutterBottom
                                            variant="h6"
                                        >
                                            Order Loss
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            {counts.loss_leads}
                                        </Typography>
                                        <Typography
                                            color="textPrimary"
                                            variant="h6"
                                        >
                                            Rs. {indianNumberFormat(counts.loss_amount)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </div>
    )
}

export default Dashboard
