import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../../components/Table'
import axios from 'axios'
import config from '../../../../config'
import VisibilityIcon from '@material-ui/icons/Visibility'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

import { dateFormate } from '../../../../functions'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListPendingFollowup = (props) => {
    const token = localStorage.getItem('bsToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "name",
            label: "Ex. Name",
            options: {
                sort: false,
            }
        },
        {
            name: "company",
            label: "Company Name",
            options: {
                sort: false,
            }
        },
        {
            name: "person_name",
            label: "Contact Person",
            options: {
                sort: false,
            }
        },
        {
            name: "person_number",
            label: "Mobile No",
            options: {
                sort: false,
            }
        },
        {
            name: "remarks",
            label: "Remarks",
            options: {
                sort: false,
            }
        },
        {
            name: "next_date",
            label: "Next Followup Date",
            options: {
                sort: false,
                customBodyRender: dateFormate
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/leads/followups/pending'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = '/dashboard/crm/leads/' + item.lead_id
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={viewUrl}>
                                <Tooltip title="View Lead">
                                    <IconButton>
                                        <VisibilityIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                        </ButtonGroup>
                    </div>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>Pending Followups</h1></Typography>
                </Breadcrumbs>
                <Breadcrumbs aria-label="breadcrumb">
                    <MLink color="inherit" href="/#/dashboard">
                        Dashboard
                    </MLink>
                </Breadcrumbs>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <div className="entry-div">
                <Table
                    title={'View Pending Lead Followups'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div>
    )
}

export default ListPendingFollowup
