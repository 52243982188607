import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    LinearProgress,
    Link as MLink,
    Typography
} from '@material-ui/core';
import axios from 'axios'
import config from '../../../config'

import DeleteIcon from '@material-ui/icons/Delete'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewLeadCustomer = (props) => {
    const customerId = props.match.params.customerId
    const token = localStorage.getItem('bsToken')
    const [lead, setLead] = useState({
        persons: []
    })
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/crm/customers/' + customerId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setLead(item)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const deleteItem = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }

        const url = config.url + '/crm/customers/' + customerId
        axios.delete(url, headerConfig).then(response => {
            setLoading(false)
            setOpenDelete(false)
            props.history.push('/dashboard/crm/customers')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleClickOpenDelete = () => {
        setOpenDelete(true)
    }

    const handleClose = () => {
        setOpenDelete(false)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>View Lead Customer</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                            <MLink color="inherit" href="/#/dashboard/crm/customers">
                                CRM - Customers
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Button variant="contained" color="primary" onClick={handleClickOpenDelete}><DeleteIcon />&nbsp;Delete</Button>
                    </Grid>
                </Grid>
            </div>
            <Dialog
                open={openDelete}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Lead Customer</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Lead Customer ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={deleteItem} variant="outlined" disabled={loading} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <div className="entry-div">
                <Card>
                    <CardContent>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12} lg={12}>
                                <div className="before">
                                    <h2 className="form-heading">Customer Details</h2>
                                </div>
                                <hr />
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderRight: "unset" }}>
                                <b>Company Name</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderRight: "unset" }}>
                                {lead.company}
                            </Grid>
                            {/* <Grid item xs={12} lg={2}>
                                <b>Owner Name</b>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                {lead.owner_name}
                            </Grid> */}
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderRight: "unset" }}>
                                <b>Owner Mobile</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border">
                                {lead.owner_mobile}
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                <b>Owner Email</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                {lead.owner_email}
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                <b>Address</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                {lead.address}
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                <b>Country</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                {lead.country}
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                <b>State</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                {lead.state}
                            </Grid>
                            <Grid item xs={12} lg={2} className="grid-border" style={{ borderTop: "unset", borderRight: "unset" }}>
                                <b>City</b>
                            </Grid>
                            <Grid item xs={12} lg={4} className="grid-border" style={{ borderTop: "unset" }}>
                                {lead.city}
                            </Grid>
                            {
                                (lead.persons.length > 0) ? (
                                    <Grid item xs={12} lg={12}>
                                        <div className="before mt-4">
                                            <h2 className="form-heading">Contact Details</h2>
                                        </div>
                                        <hr />
                                    </Grid>
                                ) : ''
                            }
                            {lead.persons.map((person, i) => {
                                let style1
                                let style2
                                if (i === 0) {
                                    style1 = {
                                        borderRight: "unset"
                                    }
                                    style2 = {}
                                } else {
                                    style1 = {
                                        borderTop: "unset",
                                        borderRight: "unset"
                                    }
                                    style2 = { borderTop: "unset" }
                                }
                                const item = (
                                    <>
                                        <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                            <b>Name</b>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="grid-border" style={style1}>
                                            {person.name}
                                        </Grid>
                                        <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                            <b>Mobile</b>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="grid-border" style={style1}>
                                            {person.number}
                                        </Grid>
                                        <Grid item xs={12} lg={1} className="grid-border" style={style1}>
                                            <b>Email</b>
                                        </Grid>
                                        <Grid item xs={12} lg={3} className="grid-border" style={style2}>
                                            {person.email}
                                        </Grid>
                                    </>
                                )
                                return item
                            })}
                            {/* <Grid item xs={12} lg={2}>
                                <b>Name</b>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                : {lead.person_name}
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <b>Mobile</b>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                : {lead.person_number}
                            </Grid>
                            <Grid item xs={12} lg={2}>
                                <b>Email</b>
                            </Grid>
                            <Grid item xs={12} lg={4}>
                                : {lead.person_email}
                            </Grid> */}
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </div>
    )
}

export default ViewLeadCustomer
