import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import config from '../../config'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListFaqs = (props) => {
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const token = localStorage.getItem('bsToken')
    const [open, setOpen] = useState(false)
    const [userId, setUserId] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "title",
            label: "Title",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = config.url + '/faqs'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(faq => {
                const editUrl = 'faqs/' + faq.id
                const action = (
                    <div>
                        <ButtonGroup size="small" aria-label="small outlined button group">
                            <Link to={editUrl}>
                                <Tooltip title="Edit FAQ">
                                    <IconButton>
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </Link>
                            <Tooltip title="Delete FAQ">
                                <IconButton onClick={() => handleClickOpen(faq.id)}>
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                )
                faq.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
            } else {
                console.log(error)
            }
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (faqId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = config.url + '/faqs/' + faqId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('FAQ deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                else {
                    message = 'Try after some time.'
                }
                console.log(error.response)
            } else if (error.request) {
                console.log(error.request)
                message = 'Try after some time.'
            } else {
                console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (userId) => {
        setOpen(true)
        setUserId(userId)
    }

    const handleClose = () => {
        setOpen(false)
        setUserId(null)
    }


    return (
        <div>
            <div className="breadcrumb1">
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="textPrimary"><h1>FAQs</h1></Typography>
                </Breadcrumbs>
                <Grid container
                    className="mt-2"
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                >
                    <Grid item >
                        <Breadcrumbs aria-label="breadcrumb">
                            <MLink color="inherit" href="/#/dashboard">
                                Dashboard
                            </MLink>
                        </Breadcrumbs>
                    </Grid>
                    <Grid item className="mr-4">
                        <Link to="faqs/add"><Button variant="contained" color="primary"><AddIcon />Add FAQ</Button></Link>
                    </Grid>
                </Grid>
            </div>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Delete FAQ"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the FAQ ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(userId)} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <div className="entry-div">
                <Table
                    title={'List FAQs'}
                    serverSide={true}
                    count={count}
                    columns={columns}
                    data={tableData}
                    onTableChange={handleTableChange}
                    rowsPerPage={limit}
                />
            </div>
        </div>
    )
}

export default ListFaqs
